import { ModelElement } from './model-element';

export class SystemLoadcase extends ModelElement {
    deadWeight = ''; // IDSLC_CALCULATION_WITH_OWN_WEIGHT
    acceleration = 0; // IDSLC_ACCELERATION
    epsx = 0; // IDSLC_EPSILON_X
    epsy = 0; // IDSLC_EPSILON_Y
    epsz = 0; // IDSLC_EPSILON_Z

    // returns the current diameter of the segment, corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentDiameter: (x: number) => number;
}

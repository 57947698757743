import { ModelElement } from '../../cae-model/model-element';
import { ModeShapeSection } from '../../cae-model/mode-shape-section';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { BasicMediator } from './basic-mediator';

export class NaturalModeShapeSectionMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_DYNAMIC_SIMULATION_MODESHAPE_SECTION'];
    }

    protected createModelElement(): ModelElement {
        return new ModeShapeSection();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDMSSD_X' },

            { left: 'wx_real', right: 'IDMSSD_WX_REAL' },
            { left: 'wx_imag', right: 'IDMSSD_WX_IMAG' },
            { left: 'wy_real', right: 'IDMSSD_WY_REAL' },
            { left: 'wy_imag', right: 'IDMSSD_WY_IMAG' },
            { left: 'wz_real', right: 'IDMSSD_WZ_REAL' },
            { left: 'wz_imag', right: 'IDMSSD_WZ_IMAG' },

            { left: 'phix_real', right: 'IDMSSD_PHIX_REAL' },
            { left: 'phix_imag', right: 'IDMSSD_PHIX_IMAG' },
            { left: 'phiy_real', right: 'IDMSSD_PHIY_REAL' },
            { left: 'phiy_imag', right: 'IDMSSD_PHIY_IMAG' },
            { left: 'phiz_real', right: 'IDMSSD_PHIZ_REAL' },
            { left: 'phiz_imag', right: 'IDMSSD_PHIZ_IMAG' },

            { left: 'fsx_real', right: 'IDMSSD_FSX_REAL' },
            { left: 'fsx_imag', right: 'IDMSSD_FSX_IMAG' },
            { left: 'fsy_real', right: 'IDMSSD_FSY_REAL' },
            { left: 'fsy_imag', right: 'IDMSSD_FSY_IMAG' },
            { left: 'fsz_real', right: 'IDMSSD_FSZ_REAL' },
            { left: 'fsz_imag', right: 'IDMSSD_FSZ_IMAG' },

            { left: 'msx_real', right: 'IDMSSD_MSX_REAL' },
            { left: 'msx_imag', right: 'IDMSSD_MSX_IMAG' },
            { left: 'msy_real', right: 'IDMSSD_MSY_REAL' },
            { left: 'msy_imag', right: 'IDMSSD_MSY_IMAG' },
            { left: 'msz_real', right: 'IDMSSD_MSZ_REAL' },
            { left: 'msz_imag', right: 'IDMSSD_MSZ_IMAG' },
        ];
        this.addBinder(binder);
    }
}

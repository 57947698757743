import { InternalStepView2D } from '../elements-view/notches/internal-step-view-2d';
import { KeySeatView2D } from '../elements-view/notches/key-seat-view-2d';
import { HubView2D } from '../elements-view/notches/hub-view-2d';
import { ThrustBallView2D } from '../elements-view/supports/bearing/thrust-ball-view-2d';
import { ThrustBall } from '../../cae-model/supports/bearings/thrust-ball';
import { SlewingBallView2D } from '../elements-view/supports/bearing/slewing-ball-view-2d';
import { SlewingBall } from '../../cae-model/supports/bearings/slewing-ball';
import { RadialRoller } from '../../cae-model/supports/bearings/radial-roller';
import { RadialBall } from '../../cae-model/supports/bearings/radial-ball';

import { Injectable, OnDestroy } from '@angular/core';
import { SupportGeometryFactory, SupportGeometryFactoryInput } from '../../views-foundation/services/support-geometry-factory';
import { ModelElementFinder } from '../../views-foundation/services/cae-element-finder.service';
import { ShaftSystemView2D } from '../elements-view/shaft-system-view-2d';
import { ShaftSystem } from '../../cae-model/shaft-system';
import { ConeSegmentView2D } from '../elements-view/shaft-segments/cone-segment-view-2d';
import { ShaftView2D } from '../elements-view/shaft-view-2d';
import { Shaft } from '../../cae-model/shaft';
import { PipeSegment } from '../../cae-model/shaft-segments/pipe-segment';
import { ElementView2DInput } from '../elements-view/element-view-2d-input';
import { SelectedElementService } from '../../views-foundation/services/selected-element.service';
import { ModelElementFinder2DService } from './model-element-finder2D.service';
import { ModelElement } from '../../cae-model/model-element';
import { CAEModel } from '../../cae-model/cae-model';
import { View2dService } from './view2d.service';
import { ElementView2D } from '../elements-view/element-view-2d';
import { PipeSegmentView2D } from '../elements-view/shaft-segments/pipe-segment-view-2d';
import { Y_2D_VIEW } from '../elements-view/view-2d-constants';
import { ConeSegment } from '../../cae-model/shaft-segments/cone-segment';
import { EditPermissionService } from '../../views-foundation/services/edit-permission.service';
import { PointLoad } from '../../cae-model/loads/point-load';
import { PointLoadView2D } from '../elements-view/loads/point-load-view-2d';
import { DistributedLoad } from '../../cae-model/loads/distributed-load';
import { DistributedLoadView2D } from '../elements-view/loads/distributed-load-view-2d';
import { PointMass } from '../../cae-model/loads/point-mass';
import { PointMassView2D } from '../elements-view/loads/point-mass-view-2d';
import { Spring } from '../../cae-model/supports/spring';
import { SpringView2D } from '../elements-view/supports/spring/spring-view-2d';
import { SupportView2D } from '../elements-view/supports/support-view-2d';
import { Support } from '../../cae-model/supports/support';
import { Bearing } from '../../cae-model/supports/bearings/bearing';
import { BearingView2D } from '../elements-view/supports/bearing/bearing-view-2d';
import { RigidSupport } from '../../cae-model/supports/rigid-support';
import { RigidSupportView2D } from '../elements-view/supports/rigid/rigid-support-view-2d';
import { RadialBallView2D } from '../elements-view/supports/bearing/radial-ball-view-2d';
import { SpurGear } from '../../cae-model/transmission-elements/spur-gear';
import { SpurGearView2D } from '../elements-view/transmission-elements/spur-gear/spur-gear-view-2d';
import { RadialRollerView2D } from '../elements-view/supports/bearing/radial-roller-view-2d';
import { InternalSpurGear } from '../../cae-model/transmission-elements/internal-spur-gear';
import { InternalSpurGearView2D } from '../elements-view/transmission-elements/internal-spur-gear/internal-spur-gear-view-2d';
import { BevelGear } from '../../cae-model/transmission-elements/bevel-gear';
import { BevelGearView2D } from '../elements-view/transmission-elements/bevel-gear/bevel-gear-view-2d';
import { InternalBevelGear } from '../../cae-model/transmission-elements/internal-bevel-gear';
import { InternalBevelGearView2D } from '../elements-view/transmission-elements/internal-bevel-gear/internal-bevel-gear-view-2d';
import { RectangularGroove } from '../../cae-model/notches/rectangular-groove';
import { RectangularGrooveView2D } from '../elements-view/notches/rectangular-groove-view-2d';
import { UnderCut } from '../../cae-model/notches/under-cut';
import { UnderCutView2D } from '../elements-view/notches/under-cut-view-2d';
import { VNotch } from '../../cae-model/notches/v-notch';
import { VNotchView2D } from '../elements-view/notches/v-notch-view-2d';
import { Hub } from '../../cae-model/notches/hub';
import { UGroove } from '../../cae-model/notches/u-groove';
import { UGrooveView2D } from '../elements-view/notches/u-groove-view-2d';
import { Shoulder } from '../../cae-model/notches/shoulder';
import { ShoulderView2D } from '../elements-view/notches/shoulder-view-2d';
import { TransverseBore } from '../../cae-model/notches/transverse-bore';
import { TransverseBoreView2D } from '../elements-view/notches/transverse-bore-view-2d';
import { Worm } from '../../cae-model/transmission-elements/worm';
import { WormView2D } from '../elements-view/transmission-elements/worm/worm-view-2d';
import { WormGear } from '../../cae-model/transmission-elements/worm-gear';
import { WormGearView2D } from '../elements-view/transmission-elements/worm-gear/worm-gear-view-2d';
import { GearAbstract } from '../../cae-model/transmission-elements/gear-abstract';
import { GearAbstractView2D } from '../elements-view/transmission-elements/gear-abstract/gear-abstract-view-2d';
import { CounterMoment } from '../../cae-model/transmission-elements/counter-moment';
import { CounterMomentView2D } from '../elements-view/transmission-elements/counter-moment/counter-moment-view-2d';
import { InternalStep } from '../../cae-model/notches/internal-step';
import { KeySeat } from '../../cae-model/notches/key-seat';
import { SelectedInViewerElementInterface } from '../../views-foundation/interfaces/selected-in-viewer-element-interface';
import { DimensionVisualizerService } from '../dimensions/dimensions-visualizer.service';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CylindricalPlainBush } from '../../cae-model/supports/sliding-bearings/cylindrical-plain-bush';
import { CylindricalPlainBushView2D } from '../elements-view/supports/sliding-bearing/cylindrical-plain-bush-view-2d';
import { RadialSphericalPlain } from '../../cae-model/supports/sliding-bearings/radial-spherical-plain';
import { AngularSphericalPlain } from '../../cae-model/supports/sliding-bearings/angular-spherical-plain';
import { ThrustWasher } from '../../cae-model/supports/sliding-bearings/thrust-washer';
import { RadialSphericalPlainView2D } from '../elements-view/supports/sliding-bearing/radial-spherical-plain-view-2d';
import { AngularSphericalPlainView2D } from '../elements-view/supports/sliding-bearing/angular-spherical-plain-view-2d';
import { ThrustWasherView2D } from '../elements-view/supports/sliding-bearing/thrust-washer-view-2d';
import { StaticFileLoadersService } from './static-file-loaders.service';
import { SupportViewInput } from '../../views-foundation/interfaces/support-view-input-interface';
import { View2DSettings } from '../settings/view-2d-settings';
import { FPS_UNIT_SCALE, SI_UNIT_SCALE } from '../../views-foundation/views-foundation-constants';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { UnbalanceView2D } from '../elements-view/loads/unbalance-view-2d';
import { Unbalance } from '../../cae-model/loads/unbalance';

@Injectable()
export class View2DFactoryService implements OnDestroy {
    private _settings: View2DSettings;
    private _clearViews$ = new Subject<void>();

    constructor(
        private _view2dService: View2dService,
        private _modelElementFinderService: ModelElementFinder2DService,
        private _selectedElementService: SelectedElementService,
        private _editPermissionService: EditPermissionService,
        private _modelElementFinder: ModelElementFinder,
        private _supportGeometryFactory: SupportGeometryFactory,
        private _staticFileLoadersService: StaticFileLoadersService,
        private _dimensionVisualizerService: DimensionVisualizerService,
    ) {}

    ngOnDestroy(): void {
        this._clearViews$.next();
        this._modelElementFinderService.ngOnDestroy();
        this._dimensionVisualizerService.destroy();
    }

    generateModelView(model: CAEModel, settings: View2DSettings): void {
        this._settings = settings;
        const layer = new Layer();
        this._clearViews$.next();
        // if we load new models, we have to clean up old models
        this._modelElementFinderService.clearViews();
        let modelView: Group | null = null;
        if (model !== null) {
            modelView = this._createElementAndChildrenView(model);
            modelView.y(Y_2D_VIEW);
            layer.add(modelView);
        }
        const views = this._modelElementFinderService.views;
        views.forEach(view => {
            view.updateAfterLayerIsSet();
        });
        this._view2dService.updateLayer(layer);

        if (modelView != null) {
            this._dimensionVisualizerService.setModelView(modelView);
        }
    }

    private _createElementAndChildrenView(elementModel: ModelElement): Group {
        const elementView = this._createKonvaGroup(elementModel);

        const elementChildren = elementModel.children;
        elementChildren.forEach(modelElement => {
            const childView = this._createElementAndChildrenView(modelElement);
            elementView.add(childView);
        });
        return elementView;
    }

    private _getElementView2DInput(): ElementView2DInput {
        return {
            setSelected: (selectedElementInterface: SelectedInViewerElementInterface) =>
                this._view2dService.clickEvent$.next(selectedElementInterface),
            getSelectedElement: () => this._selectedElementService.getSelectedElement().pipe(takeUntil(this._clearViews$)),
            isModelEditable: () => this._editPermissionService.isEditable().pipe(takeUntil(this._clearViews$)),
            getSVGImageString: (url: string) => this._staticFileLoadersService.getSVGImageString(url).pipe(takeUntil(this._clearViews$)),
            unitScaleFactor: this._getUnitScaleFactor(this._settings.unitSet),
        };
    }

    private _getUnitScaleFactor(unitSet: UnitSet): number {
        if (unitSet === UnitSet.FPS) {
            return FPS_UNIT_SCALE;
        } else {
            return SI_UNIT_SCALE;
        }
    }

    private _createKonvaGroup(modelElement: ModelElement): Group {
        const view: ElementView2D | null = this._createView(modelElement);
        if (!view) {
            const dummyGroup = new Group();
            dummyGroup.name(modelElement.name);
            return dummyGroup;
        }
        if (view instanceof SupportView2D) {
            this._setSupportViewInput(view);
        }
        const elementViewInput = this._getElementView2DInput();
        const output = view.createGroupWithChildren(elementViewInput);
        this._modelElementFinderService.addView(modelElement, view);
        return output;
    }

    private _setSupportViewInput(supportView: SupportView2D) {
        const factoryInput: SupportGeometryFactoryInput = {
            findModelElement: (elementId: string) => this._modelElementFinder.findElement(elementId),
        };
        const createGeometryFunc = (support: Support, unitScale: number) =>
            this._supportGeometryFactory.createSupportGeometry(factoryInput, support, unitScale);

        const supportViewInput: SupportViewInput = {
            createGeometry: (support: Support, unitScale: number) => createGeometryFunc(support, unitScale),
        };
        supportView.supportViewInput = supportViewInput;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private _createView(modelElement: ModelElement): ElementView2D | null {
        let view: ElementView2D | null = null;

        if (modelElement instanceof ShaftSystem) {
            view = new ShaftSystemView2D(modelElement);
        } else if (modelElement instanceof Shaft) {
            view = new ShaftView2D(modelElement);
        } else if (modelElement instanceof PipeSegment) {
            view = new PipeSegmentView2D(modelElement);
        } else if (modelElement instanceof ConeSegment) {
            view = new ConeSegmentView2D(modelElement);
        } else if (modelElement instanceof PointLoad) {
            view = new PointLoadView2D(modelElement);
        } else if (modelElement instanceof DistributedLoad) {
            view = new DistributedLoadView2D(modelElement);
        } else if (modelElement instanceof PointMass) {
            view = new PointMassView2D(modelElement);
        } else if (modelElement instanceof Unbalance) {
            view = new UnbalanceView2D(modelElement);
        } else if (modelElement instanceof Spring) {
            view = new SpringView2D(modelElement);

            // bearings
        } else if (modelElement instanceof RadialBall) {
            view = new RadialBallView2D(modelElement);
        } else if (modelElement instanceof RadialRoller) {
            view = new RadialRollerView2D(modelElement);
        } else if (modelElement instanceof SlewingBall) {
            view = new SlewingBallView2D(modelElement);
        } else if (modelElement instanceof ThrustBall) {
            view = new ThrustBallView2D(modelElement);
        } else if (modelElement instanceof Bearing) {
            view = new BearingView2D(modelElement);
        } else if (modelElement instanceof CylindricalPlainBush) {
            view = new CylindricalPlainBushView2D(modelElement);
        } else if (modelElement instanceof RadialSphericalPlain) {
            view = new RadialSphericalPlainView2D(modelElement);
        } else if (modelElement instanceof AngularSphericalPlain) {
            view = new AngularSphericalPlainView2D(modelElement);
        } else if (modelElement instanceof ThrustWasher) {
            view = new ThrustWasherView2D(modelElement);
        } else if (modelElement instanceof Worm) {
            view = new WormView2D(modelElement);
        } else if (modelElement instanceof WormGear) {
            view = new WormGearView2D(modelElement);
        } else if (modelElement instanceof GearAbstract) {
            view = new GearAbstractView2D(modelElement);
        } else if (modelElement instanceof CounterMoment) {
            view = new CounterMomentView2D(modelElement);
        } else if (modelElement instanceof RigidSupport) {
            view = new RigidSupportView2D(modelElement);

            // gears
        } else if (modelElement instanceof SpurGear) {
            view = new SpurGearView2D(modelElement);
        } else if (modelElement instanceof InternalSpurGear) {
            view = new InternalSpurGearView2D(modelElement);
        } else if (modelElement instanceof BevelGear) {
            view = new BevelGearView2D(modelElement);
        } else if (modelElement instanceof InternalBevelGear) {
            view = new InternalBevelGearView2D(modelElement);

            // notches
        } else if (modelElement instanceof RectangularGroove) {
            view = new RectangularGrooveView2D(modelElement);
        } else if (modelElement instanceof UnderCut) {
            view = new UnderCutView2D(modelElement);
        } else if (modelElement instanceof VNotch) {
            view = new VNotchView2D(modelElement);
        } else if (modelElement instanceof Hub) {
            view = new HubView2D(modelElement);
        } else if (modelElement instanceof KeySeat) {
            view = new KeySeatView2D(modelElement);
        } else if (modelElement instanceof UGroove) {
            view = new UGrooveView2D(modelElement);
        } else if (modelElement instanceof Shoulder) {
            view = new ShoulderView2D(modelElement);
        } else if (modelElement instanceof InternalStep) {
            view = new InternalStepView2D(modelElement);
        } else if (modelElement instanceof TransverseBore) {
            view = new TransverseBoreView2D(modelElement);
        }
        return view;
    }
}

import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from './role.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RoleGuard {
    constructor(private readonly _roleService: RoleService) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const roles = { requiredRoles: [], ...route.data }.requiredRoles;
        if (!Array.isArray(roles)) {
            throw new Error('No requiredRoles found on route data.');
        }
        return this._roleService.userHasRoles(roles);
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(childRoute);
    }
}

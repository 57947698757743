import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CurrentObjectProviderService {
    private _currentSubject = new Map<string, BehaviorSubject<number>>();

    public static readonly LoadCase = 'SYSTEMLOADCASE';
    public static readonly Shaft = 'SHAFT';

    constructor() {}

    getLoadCase(): Observable<number> {
        return this._getSubject(CurrentObjectProviderService.LoadCase);
    }

    set loadCase(value: number) {
        this._setValue(CurrentObjectProviderService.LoadCase, value);
    }

    getShaft(): Observable<number> {
        return this._getSubject(CurrentObjectProviderService.Shaft);
    }

    set shaft(value: number) {
        this._setValue(CurrentObjectProviderService.Shaft, value);
    }

    private _setValue(childList: string, value: number) {
        this._getSubject(childList).next(value);
    }

    private _getSubject(childList: string): BehaviorSubject<number> {
        let subject = this._currentSubject.get(childList);

        if (!subject) {
            subject = new BehaviorSubject<number>(0);
            this._currentSubject.set(childList, subject);
        }
        return subject;
    }
}

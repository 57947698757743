import { Injectable, OnDestroy } from '@angular/core';
import { ComplexNumber } from '../functions/complex-number';
import { NaturalModeViewConfigInterface } from '../interfaces/natural-mode-view-config.interface';
import { CaeModelProviderService } from '../../views-foundation/services/cae-model-provider.service';
import { ModeShape } from '../../cae-model/mode-shape';
import { Shaft } from '../../cae-model/shaft';
import { ModeShapeSection } from '../../cae-model/mode-shape-section';
import { ShaftSystem } from '../../cae-model/shaft-system';
import { Params } from '../settings/natural-mode-params';
import { getModesShapes } from '../utils/modeshapes.utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NaturalMode } from '../interfaces/natural-mode.interface';

@Injectable()
export class NaturalModesService implements OnDestroy {
    private _shaftSystem: ShaftSystem;
    private readonly _destroy$ = new Subject<void>();

    public get shaftSystem(): ShaftSystem {
        return this._shaftSystem;
    }

    constructor(private _caeModelProviderService: CaeModelProviderService) {}

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public init() {
        this._caeModelProviderService
            .getModel$()
            .pipe(takeUntil(this._destroy$))
            .subscribe(caeModel => {
                if (caeModel) {
                    this._shaftSystem = caeModel.shaftSystem;
                }
            });
    }

    public getModes(viewConfig: NaturalModeViewConfigInterface): NaturalMode[] {
        return this._getNaturalModes(this.shaftSystem, viewConfig);
    }

    private _getNaturalModes(shaftSystem: ShaftSystem, viewConfig: NaturalModeViewConfigInterface): NaturalMode[] {
        const naturalModes: NaturalMode[] = [];
        shaftSystem.shafts.forEach(shaft => {
            if (viewConfig.shaftId === null || shaft.id === viewConfig.shaftId) {
                const naturalMode = this._getNaturalModeForShaft(shaft, viewConfig);
                if (naturalMode) {
                    naturalModes.push(...naturalMode);
                }
            }
        });
        return naturalModes;
    }

    private _getNaturalModeForShaft(shaft: Shaft, viewConfig: NaturalModeViewConfigInterface): NaturalMode[] | null {
        const modeShapes = getModesShapes(shaft, viewConfig);
        const modeShape = modeShapes.find(modeShape => viewConfig.modeShapeIds.includes(modeShape.id));

        if (modeShape && modeShape.modeShapeType === 'radial') {
            return this._getDeformations(shaft.id, modeShape, viewConfig);
        } else {
            return null;
        }
    }

    private _getDeformations(shaftId: string, naturalModeShape: ModeShape, viewConfig: NaturalModeViewConfigInterface): NaturalMode[] {
        const naturalModes: NaturalMode[] = [];
        naturalModeShape.sections.forEach(section => {
            const xStart = this._getXStart(section, viewConfig);
            const xReal = this._getXReal(section, viewConfig);
            const xImag = this._getXImag(section, viewConfig);
            const yReal = this._getYReal(section, viewConfig);
            const yImag = this._getYImag(section, viewConfig);
            const zReal = this._getZReal(section, viewConfig);
            const zImag = this._getZImag(section, viewConfig);
            const cmplxX0: ComplexNumber = new ComplexNumber(xReal, xImag);
            const cmplxY0: ComplexNumber = new ComplexNumber(yReal, yImag);
            const cmplxZ0: ComplexNumber = new ComplexNumber(zReal, zImag);
            const cmplxPhiX0: ComplexNumber = new ComplexNumber(section.phix_real, section.phix_imag);
            const shaftSystemMiddleDiameter = this._shaftSystem.middleDiameter;
            naturalModes.push({ xStart, cmplxX0, cmplxY0, cmplxZ0, cmplxPhiX0, shaftSystemMiddleDiameter, shaftId });
        });
        return naturalModes;
    }

    private _getXStart(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (
            viewConfig.parameterToVisualize === Params.Displacement ||
            viewConfig.parameterToVisualize === Params.Inclination ||
            viewConfig.parameterToVisualize === Params.Thrust ||
            viewConfig.parameterToVisualize === Params.Torsional
        ) {
            return modeShape.x;
        } else {
            return 0;
        }
    }

    private _getXReal(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wx_real;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phix_real;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsx_real;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msx_real;
        } else {
            return 0;
        }
    }

    private _getXImag(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wx_imag;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phix_imag;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsx_imag;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msx_imag;
        } else {
            return 0;
        }
    }

    private _getYReal(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wy_real;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiy_real;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsy_real;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msy_real;
        } else {
            return 0;
        }
    }

    private _getYImag(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wy_imag;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiy_imag;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsy_imag;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msy_imag;
        } else {
            return 0;
        }
    }

    private _getZReal(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wz_real;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiz_real;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsz_real;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msz_real;
        } else {
            return 0;
        }
    }

    private _getZImag(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wz_imag;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiz_imag;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsz_imag;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msz_imag;
        } else {
            return 0;
        }
    }
}

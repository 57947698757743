import { ModelElement } from './model-element';
import { ModeShape } from './mode-shape';

export class ModeShapesContainer extends ModelElement {
    private _modeShapes: ModeShape[];
    private _type: string;

    constructor(type: string) {
        super();
        this._type = type;
        this._modeShapes = [];
    }

    public get modeShapes(): ModeShape[] {
        return this._modeShapes;
    }

    public get type(): string {
        return this._type;
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof ModeShape) {
            this._addShape(child);
        } else {
            throw new Error('Unexpected model element type!');
        }
    }

    private _addShape(shape: ModeShape): void {
        this._modeShapes.push(shape);
    }
}

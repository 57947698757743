import { ModelElement } from './model-element';
export class ModeShapeSection extends ModelElement {
    private _wx_real: number;
    private _wx_imag: number;
    private _wy_real: number;
    private _wy_imag: number;
    private _wz_real: number;
    private _wz_imag: number;

    private _phix_real: number;
    private _phix_imag: number;
    private _phiy_real: number;
    private _phiy_imag: number;
    private _phiz_real: number;
    private _phiz_imag: number;

    private _fsx_real: number;
    private _fsx_imag: number;
    private _fsy_real: number;
    private _fsy_imag: number;
    private _fsz_real: number;
    private _fsz_imag: number;

    private _msx_real: number;
    private _msx_imag: number;
    private _msy_real: number;
    private _msy_imag: number;
    private _msz_real: number;
    private _msz_imag: number;

    constructor() {
        super();
    }

    public get wx_real(): number {
        return this._wx_real;
    }

    public set wx_real(wx_real: number) {
        this._wx_real = wx_real;
    }

    public get wx_imag(): number {
        return this._wx_imag;
    }

    public set wx_imag(wx_imag: number) {
        this._wx_imag = wx_imag;
    }

    public get wy_real(): number {
        return this._wy_real;
    }

    public set wy_real(wy_real: number) {
        this._wy_real = wy_real;
    }

    public get wy_imag(): number {
        return this._wy_imag;
    }

    public set wy_imag(wy_imag: number) {
        this._wy_imag = wy_imag;
    }

    public get wz_real(): number {
        return this._wz_real;
    }

    public set wz_real(wz_real: number) {
        this._wz_real = wz_real;
    }

    public get wz_imag(): number {
        return this._wz_imag;
    }

    public set wz_imag(wz_imag: number) {
        this._wz_imag = wz_imag;
    }

    public get phix_real(): number {
        return this._phix_real;
    }

    public set phix_real(phix_real: number) {
        this._phix_real = phix_real;
    }

    public get phix_imag(): number {
        return this._phix_imag;
    }

    public set phix_imag(phix_imag: number) {
        this._phix_imag = phix_imag;
    }

    public get phiy_real(): number {
        return this._phiy_real;
    }

    public set phiy_real(phiy_real: number) {
        this._phiy_real = phiy_real;
    }

    public get phiy_imag(): number {
        return this._phiy_imag;
    }

    public set phiy_imag(phiy_imag: number) {
        this._phiy_imag = phiy_imag;
    }

    public get phiz_real(): number {
        return this._phiz_real;
    }

    public set phiz_real(phiz_real: number) {
        this._phiz_real = phiz_real;
    }

    public get phiz_imag(): number {
        return this._phiz_imag;
    }

    public set phiz_imag(phiz_imag: number) {
        this._phiz_imag = phiz_imag;
    }

    public get fsx_real(): number {
        return this._fsx_real;
    }

    public set fsx_real(fsx_real: number) {
        this._fsx_real = fsx_real;
    }

    public get fsx_imag(): number {
        return this._fsx_imag;
    }

    public set fsx_imag(fsx_imag: number) {
        this._fsx_imag = fsx_imag;
    }

    public get fsy_real(): number {
        return this._fsy_real;
    }

    public set fsy_real(fsy_real: number) {
        this._fsy_real = fsy_real;
    }

    public get fsy_imag(): number {
        return this._fsy_imag;
    }

    public set fsy_imag(fsy_imag: number) {
        this._fsy_imag = fsy_imag;
    }

    public get fsz_real(): number {
        return this._fsz_real;
    }

    public set fsz_real(fsz_real: number) {
        this._fsz_real = fsz_real;
    }

    public get fsz_imag(): number {
        return this._fsz_imag;
    }

    public set fsz_imag(fsz_imag: number) {
        this._fsz_imag = fsz_imag;
    }

    public get msx_real(): number {
        return this._msx_real;
    }

    public set msx_real(msx_real: number) {
        this._msx_real = msx_real;
    }

    public get msx_imag(): number {
        return this._msx_imag;
    }

    public set msx_imag(msx_imag: number) {
        this._msx_imag = msx_imag;
    }

    public get msy_real(): number {
        return this._msy_real;
    }

    public set msy_real(msy_real: number) {
        this._msy_real = msy_real;
    }

    public get msy_imag(): number {
        return this._msy_imag;
    }

    public set msy_imag(msy_imag: number) {
        this._msy_imag = msy_imag;
    }

    public get msz_real(): number {
        return this._msz_real;
    }

    public set msz_real(msz_real: number) {
        this._msz_real = msz_real;
    }

    public get msz_imag(): number {
        return this._msz_imag;
    }

    public set msz_imag(msz_imag: number) {
        this._msz_imag = msz_imag;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { GROUP_HEADER } from '../group/group.interceptor';
import { TENANT_HEADER } from '../tenant/tenant.interceptor';
import { BlobConversionService } from './blob-conversion.service';
import { ThumbnailCacheService } from './thumbnail-cache.service';
import { ThumbnailType } from './thumbnail-type.model';
import { loadAppConfig } from '../util/util';

@Injectable({
    providedIn: 'root',
})
export class ThumbnailService {
    public appConfig = loadAppConfig();
    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _thumbnailCache: ThumbnailCacheService,
        private readonly _blobConversionService: BlobConversionService,
    ) {}

    public set(id: string, type: ThumbnailType, dataUrl: string): Observable<any> {
        this._thumbnailCache.add(id, type, dataUrl);

        const formData = new FormData();
        formData.append('thumbnail', this._blobConversionService.getBlobFromDataUrl(dataUrl));
        return this._httpClient.post(this._getThumbnailUrl(id, type), formData);
    }

    public get(id: string, type: ThumbnailType, tenantId?: string, groupId?: string): Observable<string> {
        const headers = {
            ...(tenantId ? { [TENANT_HEADER]: tenantId } : {}),
            ...(groupId ? { [GROUP_HEADER]: groupId } : {}),
            'x-handle-thumbnail': 'true',
        };
        return this._httpClient.get(this._getThumbnailUrl(id, type), { responseType: 'blob', headers }).pipe(
            switchMap(blob => {
                if (typeof blob === 'string') {
                    return of('');
                }
                return this._blobConversionService.getDataUrlFromBlob(blob);
            }),
            tap(dataUrl => this._thumbnailCache.add(id, type, dataUrl)),
        );
    }

    private _getThumbnailUrl(id: string, thumbnailType: ThumbnailType): string {
        return `${this.appConfig.baseUrl}${this.appConfig.apiVersion}models/${id}/thumbnail/${thumbnailType}`;
    }
}

import { Component, ContentChildren, QueryList } from '@angular/core';
import { ContextMenuItemDirective } from './context-menu-item.directive';

@Component({
    selector: 'bx-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
    @ContentChildren(ContextMenuItemDirective) items: QueryList<ContextMenuItemDirective>;

    public select(item: ContextMenuItemDirective, event: MouseEvent): void {
        event.preventDefault();
        item.emit();
    }
}

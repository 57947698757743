import { ChangeDetectionStrategy, Component, HostBinding, Input, EventEmitter, Output } from '@angular/core';
import { enterLeaveAnimation } from '../../animations/enter-leave.animation';

@Component({
    selector: 'bx-toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [enterLeaveAnimation],
})
export class ToastComponent {
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    public close = new EventEmitter<string | number>();

    @HostBinding('@enterLeaveAnimation')
    public readonly animation = true;

    @Input()
    @HostBinding('class.error')
    error = false;

    @Input()
    public spinning: boolean;

    @Input()
    public icon: string;

    @Input()
    public closeIcon: boolean;

    @Input()
    public label: string;

    @Input()
    public times: number;

    @Input()
    toastId: string;

    @Input()
    groupId: string;

    @Input()
    link?: string;

    @Input()
    linkText?: string;

    closeToast() {
        this.close.emit(this.groupId || this.toastId);
    }
}

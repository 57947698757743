import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { ElementRef, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { comparePxAndEm } from '../util/util';
import { takeUntil } from 'rxjs/operators';

export const PANEL_ANIMATION = trigger('fade', [
    state('in', style({ opacity: 1 })),
    transition('void => *', [style({ opacity: 0 }), animate('200ms')]),
    transition('default => fade-out', animate('200ms', style({ opacity: 0 }))),
]);

@Directive()
export abstract class PanelControl implements OnInit, OnDestroy {
    @ViewChild(CdkPortal, { static: false }) private readonly _portal: CdkPortal;
    @ViewChild('anchor', { static: true }) private readonly _templateAnchor: ElementRef;

    public isOpen: boolean;
    public animationState$ = new BehaviorSubject<'default' | 'fade-out'>('default');

    protected trackWidth = true;
    protected width: number | string | undefined;

    private overlayRef: OverlayRef;
    private raf: number;
    private offsetWidth: number;
    private readonly _destroy$ = new Subject<void>();

    protected constructor(
        private readonly _overlay: Overlay,
        private readonly _ngZone: { runOutsideAngular: (arg0: () => number) => void },
        private readonly _anchor?: ElementRef,
    ) {}

    public ngOnInit(): void {
        const positionStrategy = this._overlay
            .position()
            .flexibleConnectedTo(this._anchor || this._templateAnchor)
            .withFlexibleDimensions(false)
            .withPush(true)
            .withPositions([
                { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
                { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom', panelClass: 'top' },
            ]);

        const scrollStrategy = this._overlay.scrollStrategies.reposition();

        const backdropClass = 'cdk-overlay-transparent-backdrop';
        this.overlayRef = this._overlay.create({ positionStrategy, scrollStrategy, backdropClass, hasBackdrop: true });

        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.toggle(false));
        this.overlayRef
            .detachments()
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.toggle(false));
    }

    public ngOnDestroy(): void {
        window.cancelAnimationFrame(this.raf);
        this.overlayRef.dispose();
        this._destroy$.next();
        this._destroy$.complete();
    }

    public toggle(isOpen = !this.isOpen): void {
        if ((this.isOpen = isOpen)) {
            if (!this.overlayRef.hasAttached()) {
                if (this.trackWidth) {
                    this._updateWidth();
                }
                this.overlayRef.attach(this._portal);
            }
        } else {
            window.cancelAnimationFrame(this.raf);
            this.animationState$.next('fade-out');

            if (!this.animationState$.observers.length) {
                this.overlayRef.detach();
            }
        }
    }

    public fadeDone({ toState }: AnimationEvent) {
        const isFadeOut = toState === 'fade-out';
        const isDone = this.animationState$.value === 'fade-out';

        if (isFadeOut && isDone) {
            this.overlayRef.detach();
            this.animationState$.next('default');
        }
    }

    protected calculateCustomeWidth(options: string[], minWidth: number, maxWidth: number) {
        if (!!options) {
            const maxCaptionLength = Math.min(
                Math.max(minWidth, ...options.map(option => (option !== null ? option.length : 0))),
                maxWidth,
            );
            this.width = maxCaptionLength + 'rem';
        }
    }

    private _updateWidth() {
        const { offsetWidth } = (this._anchor || this._templateAnchor).nativeElement;
        if (this.offsetWidth !== offsetWidth) {
            const calculatedWidth = this._calculateWidth(offsetWidth);
            this.overlayRef.updateSize({ width: calculatedWidth });
            this.offsetWidth = offsetWidth;
        }

        this._ngZone.runOutsideAngular(() => (this.raf = window.requestAnimationFrame(() => this._updateWidth())));
    }

    private _calculateWidth(offsetWidth: number): number | string {
        if (!this.width) {
            return offsetWidth;
        }

        if (isNaN(+this.width)) {
            if (!`${this.width}`.includes('em') && !`${this.width}`.includes('rem')) {
                return offsetWidth;
            } else {
                const fontSize = +getComputedStyle((this._anchor || this._templateAnchor).nativeElement).fontSize.replace(/px/, '');
                const convertedWidth = +`${this.width}`.replace(/rem|em/, '');
                return comparePxAndEm(offsetWidth, convertedWidth, fontSize) >= 0 ? offsetWidth : this.width;
            }
        }

        return this.width;
    }
}

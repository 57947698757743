import { CommonModule, registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '../form-control/form-control.module';
import { UtilModule } from '../util/util.module';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { Scene3DSettingsComponent } from './scene3d-settings/scene3d-settings.component';
import { SettingsCardComponent } from './settings-card/settings-card.component';
import { SettingsService } from './settings.service';
import { WarningSettingsComponent } from './warning-settings/warning-settings.component';
import { DefaultViewSettingsComponent } from './default-view-settings/default-view-settings.component';

registerLocaleData(localeEN);
registerLocaleData(localeDE);

function LocaleFactory(settingsService: SettingsService): string {
    const appLocale = settingsService.applicationLocale;
    if (!appLocale) {
        console.warn('Could not determine App Locale, falling back to en-US');
    }
    return appLocale ?? 'en-US';
}

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormControlModule, UtilModule],
    declarations: [
        AppSettingsComponent,
        Scene3DSettingsComponent,
        SettingsCardComponent,
        WarningSettingsComponent,
        DefaultViewSettingsComponent,
    ],
    exports: [
        AppSettingsComponent,
        Scene3DSettingsComponent,
        SettingsCardComponent,
        WarningSettingsComponent,
        DefaultViewSettingsComponent,
    ],
})
export class SettingsModule {
    public static forRoot(): ModuleWithProviders<SettingsModule> {
        return {
            ngModule: SettingsModule,
            providers: [
                {
                    provide: LOCALE_ID,
                    useFactory: LocaleFactory,
                    deps: [SettingsService],
                },
            ],
        };
    }
}

import { TINY_3D } from './../../view-3d/settings/view-3d-constants';
import { Load } from './../../cae-model/loads/load';
import Konva from 'konva';
import {
    ARROW_HEAD_LENGTH_2D,
    AXIS_DOT_NAME,
    CONNECT_LINE_NAME,
    DASH_STROKE_POINT_LOAD,
    LINE_CAP_2D,
    LINE_JOIN_2D,
    LOAD_FILL_COLOR_2D,
    LOAD_STROKE_COLOR_2D,
    STROKE_WIDTH,
} from '../elements-view/view-2d-constants';
import { Arrow } from '../primitives/arrow';
import { ArrowInput2DInterface } from '../primitives/arrow-input-2d-interface';
import { CircleDot } from '../primitives/circle-dot';
import { CircleTimes } from '../primitives/circle-times';
import { CircleTimesInput2DInterface } from '../primitives/circle-times-input-2d-interface';
import { TorqueCircleDot } from '../primitives/torque-circle-dot';
import { TorqueCircleTimes } from '../primitives/torque-circle-times';
import { AxisArrowType, LoadType } from '../../bearinx-mediator/functions/loads-properties';
import { AxisInputArrow, AxisInputCircle } from '../../bearinx-mediator/functions/axis-input-interface';
import { ArrowGroupInputArrow, ArrowGroupInputCircle } from '../../bearinx-mediator/functions/arrow-group-input-interface';

function doCreateDashLine(pointsCoors: number[], positionX: number): Konva.Line {
    return new Konva.Line({
        x: positionX,
        points: pointsCoors,
        stroke: LOAD_STROKE_COLOR_2D,
        strokeWidth: STROKE_WIDTH,
        strokeScaleEnabled: false,
        lineCap: LINE_CAP_2D,
        lineJoin: LINE_JOIN_2D,
        dash: DASH_STROKE_POINT_LOAD,
        draggable: false,
    });
}

/// create vertical dash line y=[-currentRadius,0]
export function createLoadDashHalfPart(currentRadius: number, isUpper: boolean): Konva.Line {
    const multiplier = isUpper ? -1 : 1;
    const pointsCoors = [0, currentRadius * multiplier, 0, 0];
    return doCreateDashLine(pointsCoors, 0);
}

/// create vertical dash line y=[-currentRadius, currentRadius]
export function createLoadDashLineFullLength(currentRadius: number, positionX = 0): Konva.Line {
    const pointsCoors = [0, -currentRadius, 0, currentRadius];
    return doCreateDashLine(pointsCoors, positionX);
}

export function createConnectLine(
    yStart: number,
    yEnd: number,
    endPosition: number,
    arrowLengthStart: number,
    arrowLengthEnd: number,
): Konva.Line {
    const getYPosition = (y: number, arrowLength: number) => (y > 0 ? arrowLength : -arrowLength);
    const startingPoint: number[] = [0, getYPosition(yStart, arrowLengthStart)];
    const endingPoint: number[] = [endPosition, getYPosition(yEnd, arrowLengthEnd)];
    return new Konva.Line({
        name: CONNECT_LINE_NAME,
        points: startingPoint.concat(endingPoint),
        stroke: LOAD_STROKE_COLOR_2D,
        strokeWidth: STROKE_WIDTH,
        strokeScaleEnabled: false,
        draggable: false,
    });
}

export function getMeanValue(loadStart: number, loadEnd: number): number {
    return (loadStart + loadEnd) / 2;
}

function gedMiddleArrowLength(loadStart: number, loadEnd: number, startArrowLength: number, endArrowLength: number): number {
    return Math.abs(((loadStart > 0 ? startArrowLength : -startArrowLength) + (loadEnd > 0 ? endArrowLength : -endArrowLength)) / 2);
}

function getArrowLength(maxArrowLength: number, loadValue: number, maxLoadValue: number): number {
    return (maxArrowLength * Math.abs(loadValue)) / maxLoadValue;
}

export function getDiameter(load: Load) {
    let output = load.getCurrentDiameter(load.x);
    if (output < TINY_3D) {
        output = Math.max(load.getShaftSystemMiddleDiameter(), TINY_3D);
    }

    return output;
}

export function createAxisArrow(axisInput: AxisInputArrow): Konva.Group {
    const group = new Konva.Group();
    const { loadValue: value, arrowLength, loadType, axisArrowType, positionX = 0, unitScaleFactor } = axisInput;
    const stroke = LOAD_STROKE_COLOR_2D;
    const isNegative = value < 0;

    if (value === 0 || arrowLength === 0) {
        return group;
    }

    const startingPointAxis: number[] = [0, 0];
    const endingPointAxis: number[] =
        axisArrowType === AxisArrowType.X ? [!isNegative ? arrowLength : -arrowLength, 0] : [0, !isNegative ? arrowLength : -arrowLength];
    const arrowInput: ArrowInput2DInterface = {
        startingPoint: startingPointAxis,
        endingPoint: endingPointAxis,
        stroke: stroke,
        strokeWidth: STROKE_WIDTH,
        fillColor: LOAD_FILL_COLOR_2D,
        axisArrowType,
        positionX: positionX,
        visibleArrowHead: arrowLength >= (ARROW_HEAD_LENGTH_2D / unitScaleFactor) * 2 ? true : false,
        opacity: 1,
        unitScaleFactor,
        isNegative,
    };

    if (loadType === LoadType.Force) {
        group.add(new Arrow(arrowInput).group);
    } else if (loadType === LoadType.Torque) {
        arrowInput.isDoubleArrow = true;
        group.add(new Arrow(arrowInput).group);
    }

    return group;
}

export function createAxisCircle(axisInput: AxisInputCircle): Konva.Group {
    const group = new Konva.Group();
    const { loadValue: value, loadType, circleRadius, positionX = 0, unitScaleFactor } = axisInput;
    const stroke = LOAD_STROKE_COLOR_2D;

    if (value > 0) {
        if (loadType === LoadType.Force) {
            const circleTimesInput: CircleTimesInput2DInterface = {
                stroke: stroke,
                strokeWidth: STROKE_WIDTH,
                opacity: 1,
                radius: circleRadius,
                positionX: positionX,
            };
            group.add(new CircleTimes(circleTimesInput).group);
        } else if (loadType === LoadType.Torque) {
            group.add(new TorqueCircleTimes(stroke, circleRadius, positionX).group);
        }
    } else if (value < 0) {
        if (loadType === LoadType.Force) {
            group.add(new CircleDot(stroke, circleRadius, positionX, unitScaleFactor).group);
        } else if (loadType === LoadType.Torque) {
            group.add(new TorqueCircleDot(stroke, circleRadius, positionX).group);
        }
    } else {
        const dot = new Konva.Circle({
            name: AXIS_DOT_NAME,
            radius: circleRadius * 0.2,
            fill: stroke,
            stroke: stroke,
            strokeWidth: STROKE_WIDTH,
            x: positionX,
            strokeScaleEnabled: false,
        });
        group.add(dot);
    }

    return group;
}

export function getStartEndArrowLength(
    loadValueStart: number,
    loadValueEnd: number,
    arrowLength: number,
): {
    startArrowLength: number;
    endArrowLength: number;
} {
    const maxValue = Math.max(Math.abs(loadValueStart), Math.abs(loadValueEnd));
    const startArrowLength = getArrowLength(arrowLength, loadValueStart, maxValue);
    const endArrowLength = getArrowLength(arrowLength, loadValueEnd, maxValue);
    return { startArrowLength, endArrowLength };
}

export function createAxisInputArrow(arrowGroupInput: ArrowGroupInputArrow): AxisInputArrow[] {
    const axisInputs: AxisInputArrow[] = [];
    const {
        loadValueStart,
        loadValueEnd,
        maxArrowLength: arrowLength,
        loadType,
        axisArrowType,
        endPosition,
        unitScaleFactor,
    } = arrowGroupInput;
    const middlePosition = endPosition / 2;
    const { startArrowLength, endArrowLength } = getStartEndArrowLength(loadValueStart, loadValueEnd, arrowLength);
    const middleArrowLength = gedMiddleArrowLength(loadValueStart, loadValueEnd, startArrowLength, endArrowLength);
    axisInputs.push({ loadValue: loadValueStart, arrowLength: startArrowLength, loadType, axisArrowType, positionX: 0, unitScaleFactor });
    axisInputs.push({
        loadValue: getMeanValue(loadValueStart, loadValueEnd),
        arrowLength: middleArrowLength,
        loadType,
        axisArrowType,
        positionX: middlePosition,
        unitScaleFactor,
    });
    axisInputs.push({
        loadValue: loadValueEnd,
        arrowLength: endArrowLength,
        loadType,
        axisArrowType,
        positionX: endPosition,
        unitScaleFactor,
    });

    return axisInputs;
}

export function createAxisInputCirlCle(arrowGroupInput: ArrowGroupInputCircle): AxisInputCircle[] {
    const axisInputs: AxisInputCircle[] = [];
    const { loadValueStart, loadValueEnd, circleRadius, loadType, endPosition, unitScaleFactor } = arrowGroupInput;
    const middlePosition = endPosition / 2;
    axisInputs.push({ loadValue: loadValueStart, circleRadius: circleRadius, loadType, positionX: 0, unitScaleFactor });
    axisInputs.push({
        loadValue: getMeanValue(loadValueStart, loadValueEnd),
        circleRadius: circleRadius,
        loadType,
        positionX: middlePosition,
        unitScaleFactor,
    });
    axisInputs.push({
        loadValue: loadValueEnd,
        circleRadius: circleRadius,
        loadType,
        positionX: endPosition,
        unitScaleFactor,
    });

    return axisInputs;
}

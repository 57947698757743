import { BufferGeometry, DoubleSide, Group, Line, LineBasicMaterial, Object3D, Vector3 } from 'three';
import {
    COLOR__COUNTERROTATIONAL_CIRCLE,
    COLOR__COROTATIONAL_CIRCLE,
    RADII_COLOR,
    RADII_CONNECTING_LINE_COLOR,
    NATURAL_FORMS_GROUP,
    PHASE_STEPS,
} from '../settings/natural-mode-constants';
import { Injectable, OnDestroy } from '@angular/core';
import { AnimationService } from './animation.service';
import { NaturalModeViewConfigInterface } from '../interfaces/natural-mode-view-config.interface';
import { View3DService } from '../../view-3d/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NaturalMode } from '../interfaces/natural-mode.interface';
import { ComplexNumber } from '../functions/complex-number';
import { EndPoints } from '../interfaces/end-points.interface';

@Injectable()
export class NaturalModeView3DService implements OnDestroy {
    private _globalGroup: Group;
    private _naturalFormsGroup: Group;
    private _naturalModeView: Object3D;
    private readonly _destroy$ = new Subject<void>();
    private _lines: Line[];
    private _lineNumber = 0;

    constructor(private _animationService: AnimationService, private _view3dService: View3DService) {
        this._globalGroup = new Group();
        this._animationService
            .updateAnimation()
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this._animate());
        this._view3dService
            .getModelGroup()
            .pipe(takeUntil(this._destroy$))
            .subscribe(group => {
                if (group && group.children) {
                    this._globalGroup = group;
                    const naturalFormsGroup = this._getNaturalFormsGroup();
                    // needed after clipping to preserve natural forms visualization on model
                    if (!naturalFormsGroup && this._naturalFormsGroup && this._naturalFormsGroup.children.length > 0) {
                        this._globalGroup.add(this._naturalFormsGroup);
                        this._view3dService.updateModelGroup(this._globalGroup);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    visualize(naturalModesData: NaturalMode[], viewConfig: NaturalModeViewConfigInterface, shaftSystemRelativeScaleFactor: number) {
        this._naturalModeView = new Object3D();
        this._naturalFormsGroup = new Group();
        this._lines = [];

        this._naturalFormsGroup.name = NATURAL_FORMS_GROUP;
        this.createVisualizationData(naturalModesData, viewConfig, shaftSystemRelativeScaleFactor);

        this.removeNaturalModes();
        this._naturalFormsGroup.add(this._naturalModeView);
        this._globalGroup.add(this._naturalFormsGroup);
        this._view3dService.updateModelGroup(this._globalGroup);
    }

    removeNaturalModes() {
        const naturalFormsGroup = this._getNaturalFormsGroup();
        if (naturalFormsGroup) {
            naturalFormsGroup.removeFromParent();
        }
        this._naturalFormsGroup?.clear();
    }

    private _getNaturalFormsGroup(): Object3D | undefined {
        return this._globalGroup.getObjectByName(NATURAL_FORMS_GROUP);
    }

    private createVisualizationData(
        naturalModesData: NaturalMode[],
        viewConfig: NaturalModeViewConfigInterface,
        shaftSystemRelativeScaleFactor: number,
    ) {
        const endPoints: EndPoints[] = [];
        const phaseStep = (2 * Math.PI) / (PHASE_STEPS - 1);
        const sectionsCount = naturalModesData.length;

        // create radial lines and endpoints
        for (let i = 0; i < sectionsCount; i++) {
            const scaleFactor = shaftSystemRelativeScaleFactor * viewConfig.radialScaleFactor;
            const xStart = naturalModesData[i].xStart;
            const cmplxX0: ComplexNumber = naturalModesData[i].cmplxX0;
            const cmplxY0: ComplexNumber = naturalModesData[i].cmplxY0;
            const cmplxZ0: ComplexNumber = naturalModesData[i].cmplxZ0;
            const cmplxPhiX0: ComplexNumber = naturalModesData[i].cmplxPhiX0;
            const cmplxValue: ComplexNumber = new ComplexNumber(0, 0);
            const shaftId: string = naturalModesData[i].shaftId;
            let cmplxX: ComplexNumber = new ComplexNumber(0, 0);
            let cmplxY: ComplexNumber = new ComplexNumber(0, 0);
            let cmplxZ: ComplexNumber = new ComplexNumber(0, 0);
            let cmplxPhiX: ComplexNumber = new ComplexNumber(0, 0);

            for (let j = 0; j < PHASE_STEPS; j++) {
                const phase = phaseStep * j;
                cmplxValue.real = Math.cos(phase);
                cmplxValue.imaginary = Math.sin(phase);
                cmplxX = cmplxX0.multiply(cmplxValue);
                cmplxY = cmplxY0.multiply(cmplxValue);
                cmplxZ = cmplxZ0.multiply(cmplxValue);
                cmplxPhiX = cmplxPhiX0.multiply(cmplxValue);
                const startPoint = new Vector3(0, 0, 0);
                const endPoint = new Vector3(cmplxX.real * scaleFactor, -cmplxY.real * scaleFactor, -cmplxZ.real * scaleFactor);
                const coordinates = [startPoint, endPoint];
                const color = RADII_COLOR;
                const name = `RadialLine_${j}_${i}`;
                const xPosition = xStart;
                const line = this.createLine(coordinates, name, color, xPosition);
                this._lines.push(line);

                // create endpoints
                const complPhase = cmplxZ0.abs() !== 0 ? cmplxY0.divide(cmplxZ0).arg() : 0;
                endPoints.push({
                    endPoint: endPoint,
                    sectionNumber: i,
                    nPhaseStep: j,
                    xStart: xStart,
                    complPhase: complPhase,
                    shaftId: shaftId,
                });
            }
        }

        this._createCircularLines(endPoints, sectionsCount);
        this._createConectingLines(endPoints, sectionsCount);
        this._createTorsionalLines(endPoints, sectionsCount, viewConfig);
    }

    private _createCircularLines(endPoints: EndPoints[], sectionsCount: number) {
        for (let i = 0; i < sectionsCount; i++) {
            for (let j = 0; j < PHASE_STEPS; j++) {
                const currPoint = endPoints.find(endPoint => endPoint.nPhaseStep === j && endPoint.sectionNumber === i);
                const nextPoint = endPoints.find(endPoint => endPoint.nPhaseStep === j + 1 && endPoint.sectionNumber === i);
                if (currPoint && nextPoint && currPoint.xStart !== undefined) {
                    const coordinates = [currPoint.endPoint, nextPoint.endPoint];
                    const coRotational = currPoint.complPhase < 0 ? false : true;
                    const name = `CircularLine_${j}_${i}`;
                    const color = coRotational ? COLOR__COROTATIONAL_CIRCLE : COLOR__COUNTERROTATIONAL_CIRCLE;
                    const xPosition = currPoint.xStart;
                    const line = this.createLine(coordinates, name, color, xPosition);
                    this._lines.push(line);
                }
            }
        }
    }

    private _createConectingLines(endPoints: EndPoints[], sectionsCount: number) {
        const endPointsGrouppedByShaftId = this._getArrayMap<EndPoints>(endPoints, endPoint => endPoint.shaftId);

        for (const [shaftId, grouppedEndPoints] of endPointsGrouppedByShaftId.entries()) {
            const shaftEndPoints = grouppedEndPoints;
            for (let i = 0; i < sectionsCount; i++) {
                for (let j = 0; j < PHASE_STEPS; j++) {
                    const startPoint = shaftEndPoints.find(endPoint => endPoint.nPhaseStep === j && endPoint.sectionNumber === i);
                    const endPoint = shaftEndPoints.find(endPoint => endPoint.nPhaseStep === j && endPoint.sectionNumber === i + 1);
                    if (startPoint?.endPoint && endPoint?.endPoint && startPoint?.xStart !== undefined && endPoint?.xStart !== undefined) {
                        const { endPoint: startCoordinate, xStart: xStartCoordinateStart } = startPoint;
                        const { endPoint: endCoordinate, xStart: xStartCoordinateEnd } = endPoint;
                        const coordinates = [
                            new Vector3(startCoordinate.x + xStartCoordinateStart, startCoordinate.y, startCoordinate.z),
                            new Vector3(endCoordinate.x + xStartCoordinateEnd, endCoordinate.y, endCoordinate.z),
                        ];
                        const name = `ConnectingLine_${j}_${i}_${shaftId}`;
                        const color = RADII_CONNECTING_LINE_COLOR;
                        const line = this.createLine(coordinates, name, color);
                        this._lines.push(line);
                    }
                }
            }
        }
    }

    private _createTorsionalLines(endPoints: EndPoints[], sectionsCount: number, viewConfig: NaturalModeViewConfigInterface) {
        for (let i = 0; i < sectionsCount; i++) {
            for (let j = 0; j < PHASE_STEPS; j++) {
                const currentEndPoint = endPoints.find(endPoint => endPoint.nPhaseStep === j && endPoint.sectionNumber === i);
                if (currentEndPoint) {
                    const {
                        xStart: xStartCurrentEndPoint,
                        endPoint: { x: currentEndPointX, y: currentEndPointY, z: currentEndPointZ },
                    } = currentEndPoint;
                    const lenght = viewConfig.torsionLineScaleFactor * 100;
                    const coordinates = [
                        new Vector3(currentEndPointX + xStartCurrentEndPoint, currentEndPointY, currentEndPointZ + lenght),
                        new Vector3(currentEndPointX + xStartCurrentEndPoint, currentEndPointY, currentEndPointZ - lenght),
                    ];
                    const name = `TorsionLine_${j}_${i}`;
                    const color = RADII_COLOR;
                    const line = this.createLine(coordinates, name, color);
                    this._lines.push(line);
                }
            }
        }
    }

    private createLine(coordinates: Vector3[], name: string, color: number, xPosition?: number): Line {
        const lineGeometry = new BufferGeometry().setFromPoints(coordinates);
        const lineMaterial = new LineBasicMaterial({
            color: color,
            side: DoubleSide,
        });

        const line = new Line(lineGeometry, lineMaterial);
        line.name = name;
        line.position.x = xPosition ? xPosition : line.position.x;

        return line;
    }

    private _animate() {
        if (this._naturalModeView) {
            this._naturalModeView.clear();
            const displayedLines = this._lines.filter(
                line => line.name.split('_')[1] === this._lineNumber.toString() || line.name.split('_')[0] === 'CircularLine',
            );
            displayedLines.forEach(line => {
                this._naturalModeView.add(line);
            });
            this._lineNumber++;
            if (this._lineNumber === PHASE_STEPS) {
                this._lineNumber = 0;
            }
        }
    }

    private _getArrayMap<T>(list: T[], keyGetter: (item: T) => string): Map<string, T[]> {
        const map = new Map<string, T[]>();
        list.forEach(item => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
}

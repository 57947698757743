import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { GroupService } from 'src/modules/group/group.service';
import { ModelObject } from 'src/modules/model/model-object.model';
import { Model } from 'src/modules/model/model.model';
import { ModelService } from 'src/modules/model/model.service';
import { Roles } from 'src/modules/role/role.model';

@Injectable({ providedIn: 'root' })
export class AppBarService {
    private readonly _toggleDashboard = new Subject<void>();
    private readonly _toggleObjectTree = new Subject<void>();
    private readonly _toggleReadOnly = new Subject<void>();
    private readonly _readOnlyActive = new BehaviorSubject<boolean>(true);
    private readonly _objectTreeOpen = new BehaviorSubject<boolean>(false);
    private readonly _loadCaseCaption = new BehaviorSubject<string>('');

    public readonly toggleDashboard$ = this._toggleDashboard.asObservable();
    public readonly toggleObjectTree$ = this._toggleObjectTree.asObservable();
    public readonly toggleReadOnly$ = this._toggleReadOnly.asObservable();
    public readonly readOnlyActive$ = this._readOnlyActive.asObservable();
    public readonly objectTreeOpen$ = this._objectTreeOpen.asObservable().pipe(delay(0));
    public readonly loadCaseCaption$ = this._loadCaseCaption.asObservable();
    public model: Model<ModelObject> | undefined;

    constructor(
        public readonly _modelService: ModelService,
        public readonly _router: Router,
        private readonly _groupService: GroupService,
    ) {
        this._modelService.model$.subscribe(item => {
            this.model = item;
        });
    }

    private _checkGroup(): boolean {
        const groupId = this.model?.groupId;
        const group = this._groupService.getGroup(groupId!);
        return group ? group!.roles.some(role => role === Roles.ModelWrite) : false;
    }

    public toggleDashboard(): void {
        this._toggleDashboard.next();
    }

    public toggleObjectTree(): void {
        this._toggleObjectTree.next();
    }

    public toggleObjectTreeOpen(value: boolean): void {
        this._objectTreeOpen.next(value);
    }

    public toggleReadOnly(value?: boolean): void {
        if (value == null || value !== this._readOnlyActive.value) {
            this._toggleReadOnly.next();

            const hasUserWriteRole = this._checkGroup();
            if (this.model !== undefined && !hasUserWriteRole) {
                this._copyDemoExample();
            }
        }
    }

    private _goToCopyPage(...commands: string[]): Promise<boolean> {
        return this._router.navigate([...commands]);
    }

    private _copyDemoExample() {
        if (this.model) {
            const tenantId = this.model.tenantId as string;
            const groupId = this.model.groupId as string;

            this._goToCopyPage('editor', tenantId, groupId, this.model.id, 'copy');
        }
    }

    public toggleReadOnlyActive(value: boolean): void {
        this._readOnlyActive.next(value);
    }

    public setLoadCaseCaption(caption?: string): void {
        this._loadCaseCaption.next(caption ?? '');
    }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupService } from './group.service';
import { loadAppConfig } from '../util/util';

export const GROUP_HEADER = 'x-bearinx-groupid';

@Injectable()
export class GroupInterceptor implements HttpInterceptor {
    constructor(private readonly _groupService: GroupService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const appConfig = loadAppConfig();
        if (!req.url.startsWith(appConfig.baseUrl)) {
            return next.handle(req);
        }

        const group = this._groupService.group;
        const request = group && !req.headers.has(GROUP_HEADER) ? req.clone({ setHeaders: { [GROUP_HEADER]: group.id } }) : req;
        return next.handle(request);
    }
}

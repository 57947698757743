import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantService } from './tenant.service';
import { loadAppConfig } from '../util/util';

export const TENANT_HEADER = 'x-bearinx-tenantid';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
    constructor(private readonly _tenantService: TenantService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const appConfig = loadAppConfig();
        if (!req.url.startsWith(appConfig.baseUrl)) {
            return next.handle(req);
        }

        const tenant = this._tenantService.tenant;
        const request = tenant && !req.headers.has(TENANT_HEADER) ? req.clone({ setHeaders: { [TENANT_HEADER]: tenant.id } }) : req;
        return next.handle(request);
    }
}

export class ExportOptions {
    [key: string]: any;
    /**
     * C'tor
     * @param name Name of the export profile
     * @param createZip Flag indicates if a zip archive should be created
     * @param filename An option export file name
     */
    constructor(public name: string, public createZip = false, public filename?: string) {}
}

import { ComponentRef, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BasePrompt } from './base.prompt';
import { first, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PromptResult } from './prompt.model';

export const PROMPT_DATA = new InjectionToken<any>('PROMPT_DATA');

@Injectable({
    providedIn: 'root',
})
export class PromptService implements OnDestroy {
    private _overlayRef?: OverlayRef;
    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _overlay: Overlay, private readonly _injector: Injector) {}

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _displayPortal<T>(portal: ComponentPortal<T>, disableBackdrop?: boolean): ComponentRef<T> {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            console.warn('Displaying multiple Prompts is not supported, yet!');
            this.close();
        }

        if (!this._overlayRef) {
            const positionStrategy = this._overlay
                .position()
                .global()
                .centerVertically()
                .centerHorizontally();

            const scrollStrategy = this._overlay.scrollStrategies.block();

            this._overlayRef = this._overlay.create({
                positionStrategy,
                scrollStrategy,
                hasBackdrop: true,
                maxWidth: '100%',
                maxHeight: '100%',
            });

            if (!disableBackdrop) {
                this._overlayRef
                    .backdropClick()
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(() => this.close());
            }
        }
        return this._overlayRef.attach(portal);
    }

    public displayPrompt<T extends BasePrompt>(component: ComponentType<T>, disableBackdrop = false, data?: any): Observable<PromptResult> {
        const injector = new PortalInjector(this._injector, new WeakMap([[PROMPT_DATA, data]]));
        return this._displayPortal(new ComponentPortal(component, null, injector), disableBackdrop).instance.decisionMade.pipe(
            first(),
            tap(() => this.close()),
        );
    }

    public close() {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            this._overlayRef.detach();
            this._overlayRef.dispose();
            this._overlayRef = undefined;
        }
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessagesService, HttpStatusCode } from '../../../modules/error-handling';
import { ProjectService } from '../project.service';
import { ApiProject } from '../project.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bx-project-create',
    templateUrl: 'project-create.component.html',
    styleUrls: ['project-create.component.scss'],
})
export class ProjectCreateComponent implements OnInit, OnDestroy {
    public projectCtrl = new FormControl();
    public disabled = true;

    private _parentId: string | undefined;
    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _projectService: ProjectService,
        private readonly _router: Router,
        private readonly _errorService: ErrorMessagesService,
        private readonly _translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.projectCtrl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((value: any) => (this.disabled = !value?.name || value.name.trim().length <= 0));
        this._projectService.currentlySelected
            .pipe(takeUntil(this._destroy$))
            .subscribe(selectedProject => (this._parentId = selectedProject?.id));
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onConfirm(): void {
        const project: ApiProject = this.projectCtrl.value;
        if (this._parentId !== null && this._parentId !== undefined) {
            project.parentId = this._parentId;
        }
        this._projectService
            .create(project)
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                _ => {
                    this._router.navigate(['models', 'library']);
                    this._projectService.selectProject(this._parentId);
                },
                error => {
                    if (error.status === HttpStatusCode.Conflict) {
                        const errorMessage = this._translateService.instant('SHARED.PROJECT.NAME.ERROR_MESSAGE_DUPLICATE');
                        this._errorService.displayErrorMessage(errorMessage);
                    } else {
                        this._errorService.displayErrorMessage(error.error);
                    }
                },
            );
    }

    public onCancel(): void {
        this._router.navigate(['..']);
    }
}

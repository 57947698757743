import { ChangeDetectorRef, Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ThumbnailCacheService } from './thumbnail-cache.service';
import { ThumbnailType } from './thumbnail-type.model';
import { ThumbnailService } from './thumbnail.service';
import { ProjectCardComponent } from '../project-card/project-card.component';

@Directive({
    selector: 'bx-project-card[bxThumbnailId]',
})
export class ThumbnailDirective implements OnInit, OnDestroy {
    private readonly _destroy$ = new Subject<void>();

    @Input('bxThumbnailId')
    public id: string;

    @Input('bxCustomThumbnail')
    public customThumbnail?: boolean | null;

    @Input('bxFallbackImg')
    public fallbackSrc: string;

    // TODO: Tests overall
    @Input()
    public groupId: string;

    @Input()
    public tenantId: string;

    constructor(
        private readonly _projectCardComponent: ProjectCardComponent,
        private readonly _thumbnailService: ThumbnailService,
        private readonly _thumbnailCache: ThumbnailCacheService,
        private readonly _changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this._loadThumbnail(this.customThumbnail ? 'custom' : 'default');
    }

    private _loadThumbnail(type: ThumbnailType): void {
        this._matchOrFetchThumbnail(type)
            .pipe(takeUntil(this._destroy$))
            .subscribe(url => {
                this._projectCardComponent.image = url;
                this._changeDetectorRef.markForCheck();
            });
    }

    private _matchOrFetchThumbnail(type: ThumbnailType): Observable<string> {
        const cachedThumbnail = this._thumbnailCache.match(this.id, type);
        if (cachedThumbnail) {
            return of(cachedThumbnail);
        }

        return this._thumbnailService.get(this.id, type, this.tenantId, this.groupId).pipe(catchError(() => of(this.fallbackSrc)));
    }

    public ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}

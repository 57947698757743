import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';

import { User } from '../user/user/user.model';
import { PromptService } from '../prompt/prompt.service';
import { ConfirmLogoutPrompt } from './confirm-logout/confirm-logout.prompt';
import { PromptResult } from '../prompt/prompt.model';
import { ConfirmReLoginPrompt } from './confirm-re-login/confirm-re-login.prompt';
import { AwaitLoginPrompt } from './login-wait-blocker/await-login.prompt';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
    private readonly _user = new ReplaySubject<User>(1);
    readonly user$ = this._user.asObservable();
    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _oAuthService: OAuthService, private readonly _promptService: PromptService) {}

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    start(): void {
        this.events
            .pipe(
                filter((event: OAuthEvent) => event.type === 'session_terminated'),
                switchMap(() => this._promptService.displayPrompt(ConfirmReLoginPrompt)),
                filter(result => result === PromptResult.Confirm),
                takeUntil(this._destroy$),
            )
            .subscribe(async () => {
                await this._oAuthService.loadDiscoveryDocument();
                this._promptService.displayPrompt(AwaitLoginPrompt, true);
                this._oAuthService.initCodeFlow();
                this._promptService.close();
            });
    }

    hasValidAccessToken() {
        return this._oAuthService.hasValidAccessToken();
    }

    async setupSilentRefresh() {
        return this._oAuthService.loadDiscoveryDocument().then(() => this._oAuthService.setupAutomaticSilentRefresh());
    }

    async authenticate(): Promise<boolean> {
        return this._oAuthService.loadDiscoveryDocumentAndLogin();
    }

    async loadUser(): Promise<User> {
        const { info: user } = (await this._oAuthService.loadUserProfile()) as { info: User };
        this._user.next(user);
        return user;
    }

    get accessToken(): string {
        return this._oAuthService.getAccessToken();
    }

    get events(): Observable<OAuthEvent> {
        return this._oAuthService.events;
    }

    logout() {
        this._promptService
            .displayPrompt(ConfirmLogoutPrompt)
            .pipe(
                filter(result => result === PromptResult.Confirm),
                takeUntil(this._destroy$),
            )
            .subscribe(async () => {
                await this._oAuthService.logOut();
            });
    }
}

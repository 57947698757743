<div class="demo-info">
    <h2 class="subtitle">{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.DEMO_INFO_PAGE.TITLE2' | translate }}</h2>
    <p>{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.DEMO_INFO_PAGE.CONTENT2' | translate }}</p>
    <h2 class="subtitle">{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.DEMO_INFO_PAGE.TITLE3' | translate }}</h2>
    <p>{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.DEMO_INFO_PAGE.CONTENT3' | translate }}</p>
    <table class="bx__grid">
        <thead>
            <tr>
                <th colspan="2" class="header">
                    {{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.APPLICATION_SUPPORT_PAGE.TABLE.HEADER' | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.APPLICATION_SUPPORT_PAGE.TABLE.E-MAIL' | translate }}</td>
                <td class="e-mail">bearinx-online&#64;schaeffler.com</td>
            </tr>
            <tr>
                <td>{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.APPLICATION_SUPPORT_PAGE.TABLE.PHONE' | translate }}</td>
                <td>+49 (0) 9132 / 82 - 7575</td>
            </tr>
        </tbody>
    </table>
</div>

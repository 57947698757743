import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { loadAppConfig } from 'src/modules/util/util';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private _injector: Injector) {}

    handleError(error: any): void {
        const appConfig = loadAppConfig();

        if (isDevMode()) {
            console.error(error);
        }

        if ('suppressMessage' in error && error.suppressMessage) {
            return;
        }

        if (error.url?.startsWith(appConfig.helpBaseUrl)) {
            return;
        }
    }
}

import { Matrix4, Vector3, CylinderGeometry, ConeGeometry, SphereBufferGeometry, BufferGeometry } from 'three';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

function makeDirectedArrowGeometries(
    shaftDiameter: number,
    headDiameter: number,
    headHeight: number,
    direction: Vector3,
    isTorque: boolean,
    amptilutde: number,
): BufferGeometry[] {
    // rotation matrix R: rotationAxis = R * (1, 0, 0)^T
    function createRotationMatrix(rotationAxis: Vector3): Matrix4 {
        const clamp = (min: number, max: number) => (value: number) => (value < min ? min : value > max ? max : value);

        const u1 = new Vector3(0, 1, 0);
        const u2 = new Vector3(rotationAxis.x, rotationAxis.y, rotationAxis.z).normalize();

        const axis = new Vector3();
        axis.crossVectors(u1, u2).normalize();

        const cosAngleIn = clamp(-1.0, 1.0)(u1.dot(u2));
        const angle = Math.acos(cosAngleIn);

        const rotMatr = new Matrix4();
        rotMatr.makeRotationAxis(axis, angle);
        return rotMatr;
    }

    const mat = createRotationMatrix(direction);

    const shaft = new CylinderGeometry(shaftDiameter, shaftDiameter, amptilutde).translate(0, amptilutde * 0.5, 0).applyMatrix4(mat);

    const headForce = new ConeGeometry(headDiameter, headHeight).translate(0, amptilutde + headHeight * 0.5, 0).applyMatrix4(mat);

    if (isTorque) {
        const headTorque = new ConeGeometry(headDiameter, headHeight).translate(0, amptilutde + headHeight * 1.5, 0).applyMatrix4(mat);
        return [shaft, headForce, headTorque];
    } else {
        return [shaft, headForce];
    }
}

export interface LoadViewInterface {
    outerDiameter: number;
    direction: Vector3;
    arrowLength: number;
    isTorque: boolean;
}

export function createLoadView(data: LoadViewInterface): BufferGeometry {
    // visualizer size
    const size = 0.1;
    const epsilon = 1e-4;

    // marker sphere at shaft center
    const sphere = new SphereBufferGeometry().scale(
        data.outerDiameter * 0.5 * size,
        data.outerDiameter * 0.5 * size,
        data.outerDiameter * 0.5 * size,
    );

    if (data.direction.length() < epsilon) {
        return sphere;
    }

    const headDiameter = data.outerDiameter * 0.5 * size;
    const headHeight = data.outerDiameter * size;
    const shaftDiameter = data.outerDiameter * 0.25 * size;
    const geomContainers = makeDirectedArrowGeometries(
        shaftDiameter,
        headDiameter,
        headHeight,
        data.direction,
        data.isTorque,
        data.arrowLength,
    );
    geomContainers.push(sphere);
    return BufferGeometryUtils.mergeBufferGeometries(geomContainers);
}

import { ModelElementFinder3DService } from './services/model-element-finder-3d.service';
import { NgModule } from '@angular/core';
import {
    View3DService,
    View3DFactoryService,
    View3DSettingsService,
    CameraService,
    View3DAxesRendererService,
    View3DVisualizationService,
    GltfExporterService,
} from './services';
import { View3DComponent } from './view3D.component';
import { Picker } from './helper/picker';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [View3DComponent],
    exports: [View3DComponent],
    imports: [CommonModule, TranslateModule],
    providers: [
        View3DService,
        View3DFactoryService,
        View3DSettingsService,
        ModelElementFinder3DService,
        Picker,
        CameraService,
        View3DVisualizationService,
        View3DAxesRendererService,
        GltfExporterService,
    ],
})
export class View3DModule {}

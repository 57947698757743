import { Component, Inject } from '@angular/core';
import { BasePrompt } from '../../prompt/base.prompt';
import { TranslateService } from '@ngx-translate/core';
import { PROMPT_DATA } from 'src/modules/prompt/prompt.service';
import { ErrorPromptContent } from '../prompt-error-message.model';

@Component({
    selector: 'bx-error-retry-prompt',
    templateUrl: 'error-retry.prompt.html',
    styleUrls: ['../error-support-prompt/error-support.prompt.scss'],
})
export class ErrorRetryPrompt extends BasePrompt {
    constructor(private translate: TranslateService, @Inject(PROMPT_DATA) public data: ErrorPromptContent) {
        super();
    }
}

import { ModeShape } from '../../cae-model/mode-shape';
import { Shaft } from '../../cae-model/shaft';
import { NaturalModeViewConfigInterface } from '../interfaces/natural-mode-view-config.interface';
import { CalculationResults } from '../settings/dynamic-results-type';

export function getModesShapes(shaft: Shaft, viewConfig: NaturalModeViewConfigInterface): ModeShape[] {
    let modeShapes: ModeShape[] = [];
    const loadCaseIndex = viewConfig.loadCaseIndex;
    const loadContainer = shaft.loadContainer[loadCaseIndex];
    if (
        viewConfig.calculationResults === CalculationResults.NaturalFrequencies &&
        loadContainer &&
        loadContainer.dynamicSimulationData.naturalFrequencies
    ) {
        modeShapes = loadContainer.dynamicSimulationData.naturalFrequencies.modeShapes;
    } else if (
        viewConfig.calculationResults === CalculationResults.CriticalSpeeds &&
        loadContainer &&
        loadContainer.dynamicSimulationData.criticalSpeeds
    ) {
        modeShapes = loadContainer.dynamicSimulationData.criticalSpeeds.modeShapes;
    }
    return modeShapes;
}

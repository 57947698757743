/**
 * ShaftSystem
 */

import { ARROW_BODY_HEIGHT } from '../view-3d/settings/view-3d-constants';
import { ModelElement } from './model-element';
import { Shaft } from './shaft';
import { SystemLoadcase } from './system-loadcase';

export class ShaftSystem extends ModelElement {
    private _shafts: Shaft[];

    constructor() {
        super();
        this._shafts = [];
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof Shaft) {
            this._addShaft(child);
        }
        if (child instanceof SystemLoadcase) {
            this._addLoadCase(child);
        }
    }

    get middleDiameter(): number {
        // return mean value of all middle diameters of shafts
        const shaftsRadii = this._shafts.map(shaft => shaft.middleRadius);
        return (shaftsRadii.reduce((a, b) => a + b, 0) / shaftsRadii.length) * 2;
    }

    getCurrentOuterRadius(x: number): number {
        const listCurrentOuterRadius = this._shafts.map(shaft => shaft.getCurrentOuterRadius(x));
        if (!listCurrentOuterRadius) {
            return ARROW_BODY_HEIGHT;
        }
        return Math.max(...listCurrentOuterRadius);
    }

    private _addShaft(shaft: Shaft): void {
        this._shafts.push(shaft);
        shaft.shaftSystemMiddleDiameter = () => this.middleDiameter;
    }

    private _addLoadCase(systemLoadcase: SystemLoadcase): void {
        systemLoadcase.getCurrentDiameter = (x: number) => this.getCurrentOuterRadius(x) * 2;
    }

    public get shafts(): Shaft[] {
        return this._shafts;
    }

    public set shafts(shafts: Shaft[]) {
        this._shafts = shafts;
    }
}

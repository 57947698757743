import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AboutModule } from '../about/about.module';
import { ImprintComponent } from '../markdown-dialogs/imprint.component';
import { RoleModule } from '../role/role.module';
import { ShareModule } from '../share/share.module';
import { UserModule } from '../user/user.module';
import { BookmarkListComponent } from './bookmark-list/bookmark-list.component';
import { NavigationComponent } from './navigation.component';

@NgModule({
    imports: [CommonModule, TranslateModule, UserModule, RouterModule, RoleModule, ShareModule, AboutModule],
    declarations: [NavigationComponent, BookmarkListComponent, ImprintComponent],
    exports: [NavigationComponent],
})
export class NavigationModule {}

import { NgModule } from '@angular/core';
import { NaturalModeView3DComponent } from './natural-modes-view-3d.component';
import { NaturalModesService } from './services/natural-modes.service';
import { CameraService, View3DAxesRendererService, View3DSettingsService } from '../view-3d/services';
import { NaturalModesVisualizationService } from './services/natural-modes-visualization.service';
import { NaturalModeView3DService } from './services/natural-modes-view-3d.service';
import { AnimationService } from './services/animation.service';
import { NaturalModesPropertiesComponent } from './natural-modes-properties/natural-modes-properties.component';
import { CommonModule } from '@angular/common';
import { FormControlModule } from 'src/modules/form-control/form-control.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ListBoxComponent } from './list-box/list-box.component';
import { TranslateModule } from '@ngx-translate/core';
import { View3DModule } from '../view-3d/view3D.module';
import { CaeModelProviderService } from '../views-foundation/services/cae-model-provider.service';

@NgModule({
    declarations: [NaturalModeView3DComponent, NaturalModesPropertiesComponent, ListBoxComponent],
    exports: [NaturalModeView3DComponent, NaturalModesPropertiesComponent],
    imports: [CommonModule, FormControlModule, ReactiveFormsModule, TranslateModule, View3DModule],
    providers: [
        NaturalModeView3DService,
        NaturalModesVisualizationService,
        NaturalModesService,
        AnimationService,
        View3DSettingsService,
        CameraService,
        View3DAxesRendererService,
        CaeModelProviderService,
    ],
})
export class NaturalModesView3DModule {}

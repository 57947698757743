import { Injectable } from '@angular/core';
import { ToastService } from '../../toast/toast.service';
import { ToastIconType } from '../../toast/toast.model';
import { PromptService } from '../../prompt/prompt.service';
import { ConnectionErrorPrompt } from '../connection-error/connection-error.prompt';
import { Observable } from 'rxjs';
import { PromptResult } from '../../prompt/prompt.model';
import { ErrorPromptContent } from '../prompt-error-message.model';
import { ErrorSupportPrompt } from '../error-support-prompt/error-support.prompt';
import { ErrorRetryPrompt } from '../error-retry-prompt/error-retry.prompt';

@Injectable({
    providedIn: 'root',
})
export class ErrorMessagesService {
    constructor(private readonly _toastService: ToastService, private readonly _promptService: PromptService) {}

    displayGeneralErrorMessage() {
        this._toastService.show({
            label: 'ERRORS.GENERAL',
            icon: ToastIconType.Error,
            closeIcon: true,
        });
    }

    displayServerUnavailableErrorMessage(): Observable<PromptResult> {
        return this._promptService.displayPrompt(ConnectionErrorPrompt, true);
    }

    displayErrorMessage(message: string) {
        if (message === null || message === undefined) {
            message = 'ERRORS.UNKNOWN_ERROR';
        }

        this._toastService.show({
            label: message,
            icon: ToastIconType.Error,
            closeIcon: true,
        });
    }

    private _displayPrompt(promptComponent: any, promptErrorMessage: ErrorPromptContent): Observable<PromptResult> {
        if (promptErrorMessage === null || promptErrorMessage === undefined) {
            promptErrorMessage = {
                title: 'ERRORS.UNKNOWN_ERROR',
                message: 'ERRORS.UNKNOWN_ERROR_MESSAGE',
            };
        }
        return this._promptService.displayPrompt(promptComponent, true, promptErrorMessage);
    }

    displayErrorContactSupportComponent(promptErrorMessage: ErrorPromptContent): Observable<PromptResult> {
        return this._displayPrompt(ErrorSupportPrompt, promptErrorMessage);
    }

    displayRetryComponent(promptErrorMessage: ErrorPromptContent): Observable<PromptResult> {
        return this._displayPrompt(ErrorRetryPrompt, promptErrorMessage);
    }

    hideAllMessages() {
        this._toastService.hideAll();
    }
}

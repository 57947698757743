import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[bxFallbackImg]',
})
export class FallbackImageDirective {
    @Input() public bxFallbackImg: string;
    @HostBinding('attr.src') @Input() public src: string;

    @HostListener('error')
    public useFallback(): void {
        if (this.src !== this.bxFallbackImg) {
            this.src = this.bxFallbackImg;
        }
    }
}

import { IDO_DYNAMIC_SIMULATION_DATA_CRITICAL_SPEEDS, IDO_DYNAMIC_SIMULATION_DATA_NATURAL_FREQUENCIES } from './cae-model-constants';
import { ModelElement } from './model-element';
import { ModeShapesContainer } from './mode-shapes-container';

export class DynamicSimulationData extends ModelElement {
    private _naturalFrequencies: ModeShapesContainer | undefined;
    private _criticalSpeeds: ModeShapesContainer | undefined;

    constructor() {
        super();
    }

    public get naturalFrequencies(): ModeShapesContainer | undefined {
        return this._naturalFrequencies;
    }

    public get criticalSpeeds(): ModeShapesContainer | undefined {
        return this._criticalSpeeds;
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof ModeShapesContainer) {
            if (child.type === IDO_DYNAMIC_SIMULATION_DATA_NATURAL_FREQUENCIES) {
                this._addNaturalFrequencies(child);
            } else if (child.type === IDO_DYNAMIC_SIMULATION_DATA_CRITICAL_SPEEDS) {
                this._addCriticalSpeeds(child);
            }
        } else {
            throw new Error('Unexpected model element type!');
        }
    }

    private _addNaturalFrequencies(naturalFrequencies: ModeShapesContainer) {
        if (!this.naturalFrequencies) {
            this._naturalFrequencies = naturalFrequencies;
        } else {
            throw new Error(`Unexpected Natural Frequencies data! ${naturalFrequencies}`);
        }
    }

    private _addCriticalSpeeds(criticalSpeeds: ModeShapesContainer) {
        if (!this._criticalSpeeds) {
            this._criticalSpeeds = criticalSpeeds;
        } else {
            throw new Error(`Unexpected Critical Speeds data! ${criticalSpeeds}`);
        }
    }
}

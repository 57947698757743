import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, switchMap, map, takeUntil } from 'rxjs/operators';
import { PromptService } from '../prompt/prompt.service';
import { ProjectDeletionPromptComponent } from '../../app/project/project-deletion-prompt/project-deletion-prompt.component';
import { PromptResult } from '../prompt/prompt.model';
import { ProjectService } from '../../app/project/project.service';
import { RoleService } from './../role/role.service';
import { Roles } from './../role/role.model';

@Component({
    selector: 'bx-project-card',
    templateUrl: 'project-card.component.html',
    styleUrls: ['project-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCardComponent implements OnInit, OnDestroy {
    @Input() public caption: string;
    @Input() public id: string;
    @Input() public groupId: string;
    @Input() public tenantId: string;
    @Input() public image: string;
    @Input() @HostBinding('class.bx-selected') public selected: boolean;
    @Input() public bookmarked?: boolean;
    @Input() public enableContextMenu?: boolean;
    @Input() public bookmarkable = false;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public select = new EventEmitter<{ tenantId: string; groupId: string; id: string }>();
    @Output() public bookmarkChange = new EventEmitter<boolean>();
    @Output() public delete = new EventEmitter<void>();

    public permission$: Observable<any>;

    private readonly _destroy$ = new Subject<void>();

    setBookmarkState($event: MouseEvent) {
        $event.stopPropagation();
        this.bookmarkChange.emit(!this.bookmarked);
    }

    constructor(
        private readonly _promptService: PromptService,
        private readonly _projectService: ProjectService,
        private readonly _roleService: RoleService,
        private readonly _router: Router,
    ) {}

    public ngOnInit() {
        this.permission$ = combineLatest([
            this._roleService.userHasRoles([Roles.ProjectDelete]),
            this._roleService.userHasRoles([Roles.ProjectWrite]),
        ]).pipe(map(([allowDelete, allowUpdate]) => (!allowDelete && !allowUpdate ? false : { allowDelete, allowUpdate })));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public deleteItem() {
        this._promptService
            .displayPrompt(ProjectDeletionPromptComponent)
            .pipe(
                filter(result => result === PromptResult.Confirm),
                switchMap(() => this._projectService.delete(this.id)),
                takeUntil(this._destroy$),
            )
            .subscribe(() => this.delete.emit());
    }

    public renameItem() {
        return this._router.navigate(['project', this.id, 'rename']);
    }

    public onSelected() {
        this.select.emit({ tenantId: this.tenantId, groupId: this.groupId, id: this.id });
    }

    public setStyles(caption: HTMLElement) {
        caption.style.transform = `translateY(-${caption.scrollHeight - 40}px)`;
    }

    public clearStyles(caption: HTMLElement) {
        caption.style.transform = '';
    }

    get title() {
        return this.bookmarked ? 'PROJECT_CARD.REMOVE_BOOKMARK' : 'PROJECT_CARD.ADD_BOOKMARK';
    }
}

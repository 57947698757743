import { ModelElement } from '../../cae-model/model-element';
import { BearinxModelObject } from '../bearinx-model-object';
import { BearinxBinder } from './bearinx-binder';
import { BindingProperties, BindingType } from './binding-properties';

export class ParametersDataBinder extends BearinxBinder {
    constructor() {
        super();
        this.leftSetter = this._setLeftProperty;
    }

    private _setLeftProperty(prop: BindingProperties): void {
        if (prop.bindingType === BindingType.ToRight) {
            return;
        }
        this.proveLeftProperty(prop.left);
        const rightValue = this.objectRight[prop.right as keyof BearinxModelObject];
        if (prop.left !== 'children' && prop.left !== 'coordinatesAbs') {
            (this.objectLeft as any)[prop.left as keyof ModelElement] = rightValue;
        }
    }
}

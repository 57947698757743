import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Output } from '@angular/core';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';
import { Subject } from 'rxjs';
import { MousePositionService } from '../views-foundation/services/mouse-position.service';
import { StageScalerService } from './services/stage-scaler.service';
import { ZoomToFitService } from './services/zoom-to-fit-service';
import { BACKGROUND_COLOR_2D, CONTAINER_CLASS_NAME, Y_2D_VIEW } from './elements-view/view-2d-constants';
import { View2dService } from './services/view2d.service';
import { RulerService } from './services/ruler-service';
import { first, takeUntil } from 'rxjs/operators';
import { View2DSettingsService } from './services/view-2d-settings.service';
import { DimensionsType2D, View2DSettings } from './settings/view-2d-settings';
import { UnitSet } from '../views-foundation/view-foundation-settings';

@Component({
    selector: 'bx-app-view-2d',
    templateUrl: './view2D.component.html',
    styleUrls: ['./view2D.component.css'],
})
export class View2DComponent implements OnInit, AfterViewInit, OnDestroy {
    private _stage: Stage;
    private _layer: Layer;
    private _rulerGroup: Group | null;
    private readonly _destroy$ = new Subject<void>();

    private _isFirstLoad = true;

    @Output() event = this._view2dService.clickEvent$;

    constructor(
        private _view2dService: View2dService,
        private _stageScalerService: StageScalerService,
        private _zoomToFitService: ZoomToFitService,
        private _mousePositionService: MousePositionService,
        private elRef: ElementRef,
        private _rulerService: RulerService,
        private _view2DSettingsService: View2DSettingsService,
    ) {}

    ngOnInit(): void {
        this._isFirstLoad = true;
        this._createStage();
        this._fitStageIntoParentContainer(this._stage);
        this._view2dService.updateStage(this._stage);
        this._rulerService.initRuler();
        this._rulerGroup = this._rulerService.rulerGroup!;
        this._view2dService
            .getLayer()
            .pipe(takeUntil(this._destroy$))
            .subscribe(layer => this._setLayer(layer));
    }

    /* LIFECYCLE */
    ngAfterViewInit(): void {
        this._fitStageIntoParentContainer(this._stage);

        const container2D = this.elRef.nativeElement.querySelector(CONTAINER_CLASS_NAME);
        container2D.style.backgroundColor = BACKGROUND_COLOR_2D;
        this._zoomToFitService.zoomToFit();
    }

    ngOnDestroy(): void {
        this._destroyStage();
        this._view2dService.ngOnDestroy();
        this._stageScalerService.ngOnDestroy();
        this._rulerService.ngOnDestroy();

        if (this._rulerGroup != null) {
            this._rulerGroup.destroy();
            this._rulerGroup = null;
        }

        this._destroy$.next();
    }

    public fitView2DIntoParentContainer(): void {
        this._fitStageIntoParentContainer(this._stage);
    }

    public setSettings(activeDimensions: boolean, unitSet: string): void {
        this._view2DSettingsService
            .getSettings()
            .pipe(first(), takeUntil(this._destroy$))
            .subscribe((settings: View2DSettings) => {
                const convertDimensions = this._convertDimensions(activeDimensions);
                const convertUnitset = this._convertUnitSet(unitSet);
                const needUpdateSettings = settings.showDimensions !== convertDimensions || settings.unitSet !== convertUnitset;
                const needUpdateZoomLevel = settings.unitSet === UnitSet.SI || settings.unitSet === UnitSet.FPS;

                if (needUpdateSettings) {
                    this._view2DSettingsService.setSettings({
                        showDimensions: convertDimensions,
                        unitSet: convertUnitset,
                    });
                }

                if (needUpdateZoomLevel) {
                    this._zoomToFitService.zoomToFit();
                }
            });
    }

    private _convertUnitSet(unitSet: string): UnitSet {
        if (unitSet === UnitSet.SI) {
            return UnitSet.SI;
        } else {
            return UnitSet.FPS;
        }
    }

    private _convertDimensions(activeDimensions: boolean): DimensionsType2D {
        return activeDimensions ? DimensionsType2D.DimensionsOfElement : DimensionsType2D.Off;
    }

    private _createStage(): void {
        this._destroyStage();
        const container2D = this.elRef.nativeElement.querySelector(CONTAINER_CLASS_NAME);
        this._stage = new Stage({
            container: container2D,
            x: 0,
            width: 10,
            height: 10,
            draggable: true,
        });
        this._stageScalerService.stage = this._stage;
        this._stageScalerService
            .isRescaled()
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.updateRulerPosition());

        this._stageScalerService.resetDefaultStageScale();
        this._stage.on('mousemove', () => {
            if (this._layer != null) {
                const mousePosition = this._layer.getRelativePointerPosition();
                this._mousePositionService.setMousePosition({
                    x: mousePosition.x,
                    y: mousePosition.y - Y_2D_VIEW,
                });
            }

            const mousePos = this._stage.getPointerPosition();
            if (mousePos != null) {
                this._rulerService.setMousePosition(mousePos);
            }
        });

        this._stage.on('dragmove', () => {
            this.updateRulerPosition();
        });
    }

    private _setLayer(layer: Layer): void {
        this._stage.removeChildren();

        this._layer = layer;
        if (this._layer === null) {
            return;
        }

        this._updateRulerGroup();
        this._layer.add(this._rulerGroup!);

        this._stage.add(this._layer);
        this.updateScale();

        this._onNewLayerEvent();
    }

    private _onNewLayerEvent(): void {
        if (!this._isFirstLoad) {
            return;
        }
        this._isFirstLoad = false;
        this._zoomToFitService.zoomToFit();
    }

    get layer(): Layer {
        return this._layer;
    }

    private updateScale(): void {
        this._stageScalerService.updateScale();
    }

    private _fitStageIntoParentContainer(stage: Stage): void {
        const container = this.elRef.nativeElement.querySelector('.view2D-parent');

        // now we need to fit stage into parent
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        stage.width(containerWidth);
        stage.height(containerHeight);

        this.updateScale();
    }

    public updateRulerPosition(): void {
        this._updateRulerGroup();
    }

    private _updateRulerGroup(): void {
        if (this._rulerGroup == null) {
            return;
        }
        this._rulerGroup.setAbsolutePosition({ x: 0, y: 0 });

        const htmlContainer = this.elRef.nativeElement.querySelector(CONTAINER_CLASS_NAME);
        const containerSize: Vector2d = {
            x: htmlContainer.clientWidth,
            y: htmlContainer.clientHeight,
        };
        this._rulerService.updateRulerGroup(containerSize, this._rulerGroup.getPosition(), this._stage.scale());
    }

    private _destroyStage(): void {
        if (this._stage != null) {
            this._stage.off('mousemove');
            this._stage.off('dragmove');
            this._stage.listening(false);
            this._stage.removeChildren();
            this._stage.destroy();
        }
    }
}

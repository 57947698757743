export class ComplexNumber {
    constructor(public real: number, public imaginary: number) {}

    // Method to divide two complex numbers
    divide(other: ComplexNumber): ComplexNumber {
        const denominator = other.real ** 2 + other.imaginary ** 2;
        const realPart = (this.real * other.real + this.imaginary * other.imaginary) / denominator;
        const imaginaryPart = (this.imaginary * other.real - this.real * other.imaginary) / denominator;
        return new ComplexNumber(realPart, imaginaryPart);
    }

    // Calculate the magnitude (abslolute value) of the complex number
    abs(): number {
        return Math.sqrt(this.real ** 2 + this.imaginary ** 2);
    }

    // Calculate the argument (angle/phase) of the complex number
    arg(): number {
        return Math.atan2(this.imaginary, this.real);
    }

    // Method to multiply two complex numbers
    multiply(other: ComplexNumber): ComplexNumber {
        const realPart = this.real * other.real - this.imaginary * other.imaginary;
        const imaginaryPart = this.real * other.imaginary + this.imaginary * other.real;
        return new ComplexNumber(realPart, imaginaryPart);
    }
}

import { HttpParams } from '@angular/common/http';
import { ModelObject } from '../model/model-object.model';
import { environment } from '../../../src/environments/environment';
import { AppConfig } from './app.config';
import { devopsConfig } from 'src/devops/devops.config';

export const FONT_SIZE_IN_REM = 1.625;
export interface WindowSize {
    width: number;
    height: number;
}

export const windowSize = (): WindowSize =>
    ({
        width: window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight,
    } as WindowSize);

export const sortByName = (p1: { name: string }, p2: { name: string }) => (p1.name || '').localeCompare(p2.name || '');

export function groupBy<T extends any, K extends keyof T>(array: T[], key: K | ((obj: T) => string)): Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
        const value = keyFn(obj) as string;
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {} as Record<string, T[]>);
}

export const precisionValueFormatter = (precision: number, locale?: string, opt?: object): ((value: number) => string) => {
    const format = { minimumFractionDigits: precision, maximumFractionDigits: precision, useGrouping: false, ...(opt ?? {}) };
    const formatter = new Intl.NumberFormat(locale ?? 'en-US', format);
    return (value: number): string => formatter.format(value);
};

// Either create a trimmer or the identity function
// Example about Intl.NumberFormat:
// new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number);
// output: "1,23,000"
export const precisionFormatter = (locale: string, precision?: number): ((value: string | number) => string) => {
    if (precision !== undefined) {
        const format = precisionValueFormatter(precision, locale);
        return value => {
            const formattedValue = format(+value);
            return isNaN(parseFloat(formattedValue)) ? `${value}` : formattedValue;
        };
    }
    return value => `${value}`;
};

export const roundNumberWithPrecision = (precision?: number): ((numeric: number) => number) => {
    if (precision != null) {
        return numeric => {
            const precisionNumber = `${numeric}e${precision}`;
            return Number(`${Math.round(Number(precisionNumber))}e-${precision}`);
        };
    }
    return (numeric: number): number => numeric;
};

export const stripAndParse = (value: string): number => {
    const trimmedValue = `${value}`.replace(/\s/g, '');
    const commaCount = trimmedValue.match(/,/g)?.length ?? 0;
    const dotCount = trimmedValue.match(/\./g)?.length ?? 0;
    const lastSeparator = trimmedValue.match(/[.,]/g)?.pop();
    let result = value;

    if ((lastSeparator === ',' && commaCount > 1 && dotCount > 0) || (lastSeparator === '.' && commaCount > 0 && dotCount > 1)) {
        return 0;
    }
    if ((commaCount >= 2 && dotCount === 0) || (commaCount === 0 && dotCount >= 2)) {
        // format value ex: 1,000,000 or 1.000.000
        result = trimmedValue.replace(/[,.']/g, '');
    } else {
        result = trimmedValue
            .replace(/'/g, '')
            .replace(/,/g, '.')
            .replace(/\.(?=.*\.)/g, '');
    }

    const resolveInFloat = parseFloat(result);

    if (isNaN(resolveInFloat)) {
        return 0;
    }

    return resolveInFloat;
};

function loadingOneTrustCookie(oneTrustId: string) {
    const head = document.getElementsByTagName('head')[0];

    const oneTrustScript = document.createElement('script') as HTMLScriptElement;
    oneTrustScript.id = 'one-trust-script';
    oneTrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    oneTrustScript.setAttribute('data-document-language', 'true');
    oneTrustScript.type = 'text/javascript';
    oneTrustScript.charset = 'UTF-8';
    oneTrustScript.setAttribute('data-domain-script', oneTrustId);

    const oneTrustFunction = document.createElement('script') as HTMLScriptElement;
    oneTrustFunction.type = 'text/javascript';
    oneTrustFunction.textContent = 'function OptanonWrapper(){ }';

    // OneTrust Provider must be first entry
    head.insertBefore(oneTrustScript, head.firstChild);
    head.appendChild(oneTrustFunction);
}

let envUrls: AppConfig | null = null;

export function loadAppConfig(): AppConfig {
    if (envUrls === null) {
        const configEnvFile = devopsConfig.configEnvFile;
        // path to config
        const jsonFile = configEnvFile !== null ? environment.envUrl + configEnvFile : environment.envUrl + environment.envFile;
        const request = new XMLHttpRequest();
        request.open('GET', jsonFile, false); // get app settings
        request.send(null);
        envUrls = JSON.parse(request.responseText);

        if (envUrls) {
            // because json file cannot read variable
            envUrls.oauth.redirectUri = `${window.location.origin}${window.location.pathname}`;
            // load one trust scripts
            loadingOneTrustCookie(envUrls.oneTrustId);
        }
    }

    return envUrls!;
}

export function displayInfo(bearingName: string): void {
    const url = loadAppConfig();
    const { baseUrl, searchParam, params } = url.medias;
    const searchParams = new HttpParams().set(searchParam, bearingName);
    const optionalParams = Object.entries(params).reduce((httpParams, [param, value]) => httpParams.set(param, `${value}`), searchParams);
    window.open(`${baseUrl}?${optionalParams.toString()}`, '_blank');
}

export function getObjectPropertyValue<T>(modelObject: ModelObject, propertyName: string): T | undefined {
    const property = modelObject.properties.find(p => p.name === propertyName);
    return property?.value;
}

export function getCaption(modelObject: ModelObject): string {
    let caption = getObjectPropertyValue<string>(modelObject, 'IDCO_DESIGNATION') ?? '';
    const additionalCaptionProperty = modelObject.properties.find(property => property.name === 'IDCO_ADDITIONAL_DESIGNATION');
    if (additionalCaptionProperty) {
        caption += ` ${additionalCaptionProperty.value}`;
    }

    return caption?.trim() || modelObject.type;
}

export function comparePxAndEm(widthInPx: number, widthInEm: number, fontSize: number): number {
    return widthInPx - (widthInEm * fontSize) / FONT_SIZE_IN_REM;
}

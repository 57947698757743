import { Injectable } from '@angular/core';
import { ThumbnailType } from './thumbnail-type.model';

@Injectable({ providedIn: 'root' })
export class ThumbnailCacheService {
    private readonly _thumbnailMap = new Map<string, string>();

    public add(id: string, type: ThumbnailType, dataUrl: string): void {
        this._thumbnailMap.set(this._getCacheKey(id, type), dataUrl);
    }

    public match(id: string, type: ThumbnailType): string | undefined {
        return this._thumbnailMap.get(this._getCacheKey(id, type));
    }

    private _getCacheKey(id: string, type: ThumbnailType): string {
        return `${id}.${type}`;
    }
}

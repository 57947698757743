import { NgModule } from '@angular/core';
import { FallbackImageDirective } from './fallback-image.directive';
import { ThumbnailDirective } from './thumbnail.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThumbnailInterceptor } from './thumbnail.interceptor';

const DECLARATIONS = [FallbackImageDirective, ThumbnailDirective];

@NgModule({
    declarations: [...DECLARATIONS],
    exports: [...DECLARATIONS],
    providers: [{ provide: HTTP_INTERCEPTORS, multi: true, useClass: ThumbnailInterceptor }],
})
export class ThumbnailModule {}

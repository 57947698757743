import { Injectable } from '@angular/core';
import { WebGLRenderer, Clock } from 'three';
import { AnimationService } from './animation.service';
import { PHASE_STEPS } from '../settings/natural-mode-constants';

@Injectable()
export class NaturalModesVisualizationService {
    constructor(private _animationService: AnimationService) {}

    public render(renderer: WebGLRenderer) {
        if (renderer) {
            const clock = new Clock();
            let totalTime = 0;
            renderer.setAnimationLoop(() => {
                const timeDelta = clock.getDelta();
                totalTime += timeDelta;
                if (totalTime > 2 / PHASE_STEPS) {
                    this._animationService.animate();
                    totalTime = 0;
                }
            });
        }
    }
}

<ul>
    <li
        *ngFor="let item of items"
        [class.separateBefore]="item.separateBefore"
        [class.disabled]="item.disabled"
        (contextmenu)="select(item, $event)"
        (click)="select(item, $event)"
    >
        <i class="material-icons">{{ item.icon }}</i>
        {{ item.caption }}
    </li>
</ul>

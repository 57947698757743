import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Version } from './version.model';
import { loadAppConfig } from '../util/util';

@Injectable({
    providedIn: 'root',
})
export class VersionService implements OnDestroy {
    private readonly _frontendVersion: string = require('../../../package.json').version;
    public appConfig = loadAppConfig();
    private readonly _baseUrl = `${this.appConfig.baseUrl}version`;
    private readonly _destroy$ = new Subject<void>();
    private _versions$ = new ReplaySubject<Version[]>(1);
    readonly versions$ = this._versions$.asObservable().pipe(
        map(versions => [
            ...versions,
            {
                name: 'Frontend',
                version: this.getFrontendVersion(),
            },
        ]),
    );

    constructor(private _httpClient: HttpClient) {
        this._getVersions();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _getVersions() {
        this._httpClient
            .get<Version[]>(this._baseUrl)
            .pipe(takeUntil(this._destroy$))
            .subscribe(this._versions$);
    }

    public getFrontendVersion(): string {
        return this._frontendVersion;
    }
}

import { IDO_DYNAMIC_SIMULATION_DATA_NATURAL_FREQUENCIES } from '../../cae-model/cae-model-constants';
import { ModelElement } from '../../cae-model/model-element';
import { ModeShapesContainer } from '../../cae-model/mode-shapes-container';
import { BasicMediator } from './basic-mediator';

export class NaturalFrequencyMediator extends BasicMediator {
    constructor() {
        super();
    }

    get bearinxObjectTypes(): string[] {
        return [IDO_DYNAMIC_SIMULATION_DATA_NATURAL_FREQUENCIES];
    }

    protected createModelElement(): ModelElement {
        return new ModeShapesContainer(IDO_DYNAMIC_SIMULATION_DATA_NATURAL_FREQUENCIES);
    }
}

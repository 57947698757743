<div class="title">
    <h4 caption class="header">{{ data.title | translate }}</h4>
</div>
<div class="content">
    <p class="content-message">{{ data.message | translate }}</p>
    <table class="bx__grid">
        <thead>
            <tr>
                <th colspan="2" class="header">
                    {{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.APPLICATION_SUPPORT_PAGE.TABLE.HEADER' | translate }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.APPLICATION_SUPPORT_PAGE.TABLE.E-MAIL' | translate }}</td>
                <td class="e-mail">bearinx-online&#64;schaeffler.com</td>
            </tr>
            <tr>
                <td>{{ 'BEARINX.HOME.ASIDE.MENU.CONTENT.APPLICATION_SUPPORT_PAGE.TABLE.PHONE' | translate }}</td>
                <td>+49 (0) 9132 / 82 - 7575</td>
            </tr>
        </tbody>
    </table>
</div>
<footer class="footer">
    <div class="button-bar">
        <button (click)="cancel()" class="right secondary" translate>GLOBALS.CANCEL</button>
    </div>
</footer>

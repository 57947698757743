import { takeUntil } from 'rxjs/operators';
import { Injectable, Injector, Type, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ModalContainerComponent } from '../components/modal-container/modal-container.component';
import { ModalContext } from '../models/modal.context';
import { DialogButton, DialogButtonPosition } from '../models/dialog.preset';
import { ContentDialogComponent } from '../components/content-dialog/content-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService implements OnDestroy {
    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _overlay: Overlay, private readonly _injector: Injector) {}

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    showContentDialog(content: Type<any>, title: string, buttons: DialogButton[] = [], showClose = true): OverlayRef {
        const overlayRef = this._overlay.create();

        const context = new ModalContext(content, title, buttons);
        if (showClose) {
            context.buttons.push({
                text: 'MODAL.CLOSE',
                onClick: (contentDialog: ContentDialogComponent) => {
                    contentDialog.context.container.animationEnd$.pipe(takeUntil(this._destroy$)).subscribe(() => overlayRef.dispose());
                    contentDialog.context.container.removeAnimationClass();
                },
                cssClass: 'secondary',
                position: DialogButtonPosition.Left,
            });
        }

        const injectionTokens = new WeakMap();
        injectionTokens.set(ModalContext, context);

        const portalInjector = new PortalInjector(this._injector, injectionTokens);
        const portal = new ComponentPortal(ModalContainerComponent, null, portalInjector);
        overlayRef.attach(portal);

        return overlayRef;
    }
}

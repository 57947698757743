import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { BearinxConverterService } from './modules/bearinx-mediator/services/bearinx-converter.service';
import { CAEModel } from './modules/cae-model/cae-model';
import { View2DFactoryService } from './modules/view-2d/services/view2DFactory.service';
import { View3DSettings } from './modules/view-3d/settings';
import { View3DFactoryService, View3DSettingsService } from './modules/view-3d/services';
import { CaeModelProviderService } from './modules/views-foundation/services/cae-model-provider.service';
import { View2DSettings } from './modules/view-2d/settings/view-2d-settings';
import { View2DSettingsService } from './modules/view-2d/services/view-2d-settings.service';
import { NaturalModesService } from './modules/natural-modes-view-3d/services/natural-modes.service';

@Injectable()
/// main service for visualization of  bearinx models
export class BearinxModelVisualizerService implements OnDestroy {
    private _2DViewerSubscription?: Subscription;
    private _3DViewerSubscription?: Subscription;

    constructor(
        private _caeModelProviderService: CaeModelProviderService,
        private _viewFactory2D: View2DFactoryService,
        private _viewFactory3D: View3DFactoryService,
        private _view3DSettingsService: View3DSettingsService,
        private _view2DSettingsService: View2DSettingsService,
        private _bearinxConverterService: BearinxConverterService,
        private _naturalModesService: NaturalModesService,
    ) {}

    init(): void {
        this._bearinxConverterService.init();
        this._naturalModesService.init();
        this._2DViewerSubscription =
            this._2DViewerSubscription ??
            combineLatest([this._caeModelProviderService.getModel$(), this._view2DSettingsService.getSettings()]).subscribe(
                ([model, settings]: [CAEModel | null, View2DSettings | null]) => {
                    if (model != null && settings != null) {
                        this._generate2DView(model, settings);
                    }
                },
            );

        this._3DViewerSubscription =
            this._3DViewerSubscription ??
            combineLatest([this._caeModelProviderService.getModel$(), this._view3DSettingsService.getSettings()]).subscribe(
                ([model, settings]: [CAEModel | null, View3DSettings | null]) => {
                    if (model != null && settings != null) {
                        this._generate3DView(model, settings);
                    }
                },
            );
    }

    private _generate2DView(model: CAEModel, settings: View2DSettings): void {
        this._viewFactory2D.generateModelView(model, settings);
    }

    private _generate3DView(model: CAEModel, settings: View3DSettings): void {
        this._viewFactory3D.generateModelView(model, settings);
    }

    public ngOnDestroy() {
        this._caeModelProviderService.ngOnDestroy();
        this._bearinxConverterService.ngOnDestroy();
        this._viewFactory2D.ngOnDestroy();
        this._viewFactory3D.ngOnDestroy();
        this._2DViewerSubscription?.unsubscribe();
        this._3DViewerSubscription?.unsubscribe();
        this._naturalModesService.ngOnDestroy();
    }
}

import { Overlay } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    NgZone,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PanelControl, PANEL_ANIMATION } from '../panel-control';
import { ComboBoxOption } from './combo-box-option.model';

// TODO do not open on focus, handle blur correctly -> handle open/closed state
@Component({
    selector: 'bx-combo-box',
    templateUrl: './combo-box.component.html',
    styleUrls: ['./combo-box.component.scss'],
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ComboBoxComponent), multi: true }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [PANEL_ANIMATION],
})
export class ComboBoxComponent extends PanelControl implements ControlValueAccessor, OnChanges {
    @Input() public placeholder = '';
    @Input() public options: ComboBoxOption[] = [];
    @Input() public notRounded = false;
    @Input() public noBackground = false;
    @Input() public display = false;
    @Input() public fill = false;
    @Input() public minWidth = 10;
    @Input() public maxWidth = 30;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public filter = new EventEmitter<string>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public focus = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public blur = new EventEmitter<void>();
    @Output() public selection = new EventEmitter<string | null>();
    @Output() public updateDropDown = new EventEmitter<string>();

    public selectedId: string | null = null;
    public isDisabled = false;

    private _onChange = (_value: string | null) => void 0;
    private _onTouched = () => void 0;

    constructor(overlay: Overlay, ngZone: NgZone, private readonly _cdRef: ChangeDetectorRef) {
        super(overlay, ngZone);
    }

    public get readonly(): boolean {
        return this.filter.observers.length < 1;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.options?.currentValue != null) {
            this.calculateCustomeWidth(
                (changes!.options!.currentValue as ComboBoxOption[]).map(({ caption }) => caption),
                this.minWidth,
                this.maxWidth,
            );
        }
    }

    public onNativeBlur(): void {
        this._onTouched();
    }

    public onBlur(isNative = false): void {
        document.querySelector('.inner')?.classList.remove('noscroll');

        // native blur may not close the box
        if (isNative) {
            this._onTouched();
            return;
        }
        this.toggle(false);
        this.blur.emit();

        // in typing mode: reset input on blur, choice must be valid and made from dropdown
        if (!this.readonly) {
            // if current selectedId can still be found, keep the choice
            const foundOption = this._findOption(this.selectedId);
            this._select(foundOption ? foundOption.id : '', !!foundOption);
        }
    }

    public onFocus(): void {
        this.focus.emit();

        this.toggle(true);
    }

    public onInput(text: string): void {
        this.filter.emit(text);
    }

    public onSelect(option: ComboBoxOption): void {
        if (this.selectedId !== option.id) {
            this._select(option.id);
            this.updateDropDown.emit(option.id as string);
        }
        this.toggle(false);
    }

    public writeValue(value: string): void {
        this._select(value !== void 0 ? value : '', true);
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.isDisabled = disabled;
        this._cdRef.markForCheck();
    }

    public get selectedCaption(): string {
        const foundOption = this._findOption(this.selectedId);
        return foundOption ? foundOption.caption : this.selectedId || '';
    }

    private _findOption(id: string | null): ComboBoxOption | void {
        return (this.options || []).find(option => option.id === id);
    }

    private _select(id: string | null, suppressEmit = false): void {
        if (id === void 0) {
            return;
        }
        this.selectedId = id;
        this._cdRef.markForCheck();
        if (!suppressEmit) {
            this._onChange(id);
            this.filter.emit('');
            this.selection.emit(id);
        }
    }

    public onArrowUp() {
        if (this.isOpen) {
            const currentIndex = this.options.indexOf(this._findOption(this.selectedId)!, 0);

            if (currentIndex > 0) {
                this._select(this.options[currentIndex - 1].id);
                document.querySelector('.inner')?.classList.add('noscroll');
                document.querySelector('.option.selected')?.scrollIntoView({ block: 'center' });
            }
        }
    }

    public onArrowDown() {
        if (this.isOpen) {
            const currentIndex = this.options.indexOf(this._findOption(this.selectedId)!, 0);

            if (currentIndex < this.options.length - 1) {
                this._select(this.options[currentIndex + 1].id);
                document.querySelector('.inner')?.classList.add('noscroll');
                document.querySelector('.option.selected')?.scrollIntoView({ block: 'center' });
            }
        }
    }

    public onEnter() {
        if (this.isOpen) {
            this.toggle(false);
            document.querySelector('.inner')?.classList.remove('noscroll');
        } else {
            this.toggle(true);
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LockToken } from './lock-token.model';
import { DocumentType } from './document-type.model';
import { loadAppConfig } from '../util/util';

@Injectable({ providedIn: 'root' })
export class LockTokenApiService {
    public appConfig = loadAppConfig();

    private readonly _baseUrl = `${this.appConfig.baseUrl}locktoken/`;

    constructor(private readonly _httpClient: HttpClient) {}

    public get(documentType: DocumentType, documentId: string): Observable<LockToken> {
        return this._httpClient.get<LockToken>(`${this._baseUrl}${documentType}/${documentId}`);
    }

    public create(documentType: DocumentType, documentId: string): Observable<LockToken> {
        return this._httpClient.post<LockToken>(`${this._baseUrl}${documentType}/${documentId}`, null);
    }

    public update(documentType: DocumentType, documentId: string): Observable<LockToken> {
        return this._httpClient.put<LockToken>(`${this._baseUrl}${documentType}/${documentId}`, null);
    }

    public delete(documentType: DocumentType, documentId: string, adminOverride = false): Observable<void> {
        const headers: { [key: string]: string } = adminOverride ? { 'x-bearinx-locktoken-override': 'true' } : {};
        return this._httpClient.delete<void>(`${this._baseUrl}${documentType}/${documentId}`, { headers });
    }
}

import { ModelElement } from '../../cae-model/model-element';
import { DynamicSimulationData } from '../../cae-model/dynamic-simulation-data';
import { BasicMediator } from './basic-mediator';

export class DynamicSimulationDataMediator extends BasicMediator {
    get bearinxObjectTypes(): string[] {
        return ['IDO_DYNAMIC_SIMULATION_DATA'];
    }

    protected createModelElement(): ModelElement {
        return new DynamicSimulationData();
    }
}

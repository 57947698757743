import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[bxContextMenu]:not([bxContextMenuClick]),[bxContextMenuClick]:not([bxContextMenu])',
})
export class ContextMenuDirective {
    @Input('bxContextMenu') template: TemplateRef<any>;
    @Input('bxContextMenuClick') templateClick: TemplateRef<any>;

    @Input() payload: any;

    private _overlayRef: OverlayRef;
    private _closeSubscription = Subscription.EMPTY;

    constructor(private readonly _overlay: Overlay, private readonly _viewContainerRef: ViewContainerRef) {}

    @HostListener('contextmenu', ['$event'])
    @HostListener('click', ['$event'])
    public open(event: { x: number; y: number; type?: string; preventDefault?: () => void }, force = false): void {
        if (
            !force &&
            ((!this.template && !this.templateClick) ||
                (this.templateClick && event.type !== 'click') ||
                (this.template && event.type !== 'contextmenu'))
        ) {
            return;
        }

        if (event.preventDefault) {
            event.preventDefault();
        }

        this._close();

        this._overlayRef = this._overlay.create(this._makeOverlayConfig(event.x, event.y));
        this._overlayRef.attach(
            new TemplatePortal(this.template || this.templateClick, this._viewContainerRef, {
                $implicit: this.payload,
            }),
        );

        // click event closes immediately, therefore only register close handler in next frame
        setTimeout(() => {
            this._closeSubscription = this._close$().subscribe(() => this._close());
        }, 0);
    }

    private _close(): void {
        this._closeSubscription.unsubscribe();
        this._overlayRef?.dispose();
    }

    private _makeOverlayConfig(x: number, y: number): OverlayConfig {
        const positionStrategy = this._overlay
            .position()
            .flexibleConnectedTo({ x, y })
            .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]);
        const scrollStrategy = this._overlay.scrollStrategies.close();
        return { positionStrategy, scrollStrategy };
    }

    private _close$(): Observable<any> {
        const click$ = fromEvent<MouseEvent>(document, 'click');
        const context$ = fromEvent<MouseEvent>(document, 'contextmenu');
        return merge(click$, context$).pipe(take(1));
    }
}

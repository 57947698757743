import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'bx-context-menu-item',
})
export class ContextMenuItemDirective {
    @Input() caption = 'No Caption';
    @Input() icon = '';
    @Input() separateBefore = false;
    @Input() disabled = false;
    @Output() action = new EventEmitter<void>();

    public emit(): void {
        if (!this.disabled) {
            this.action.emit();
        }
    }
}

import { ModelElement } from '../../cae-model/model-element';
import { getDimensionDependentProperty, getProperty, setProperty } from '../functions/utils';
import { BearinxBinder } from './bearinx-binder';
import { BindingProperties, BindingType } from './binding-properties';

export class BearinxStandardBinder extends BearinxBinder {
    constructor() {
        super();
        this.leftSetter = this._setLeftProperty;
        this.rightSetter = this._setRightProperty;
    }

    private _setLeftProperty(prop: BindingProperties): void {
        if (prop.bindingType === BindingType.ToRight) {
            return;
        }
        this.proveLeftProperty(prop.left);
        const rightValue =
            prop.dimension == null
                ? getProperty(this.objectRight, prop.right)
                : getDimensionDependentProperty(this.objectRight, prop.right, prop.dimension);
        ((this.objectLeft as unknown) as { [key: string]: string | number })[prop.left] = rightValue;
    }

    private _setRightProperty(prop: BindingProperties): void {
        if (prop.bindingType === BindingType.ToLeft) {
            return;
        }
        const leftValue = this.objectLeft[prop.left as keyof ModelElement]; // <number>getProperty(this.objectRight, prop.right, undefined);
        if (!leftValue) {
            return;
        }
        if (typeof leftValue === 'string' || typeof leftValue === 'number') {
            setProperty(this.objectRight, prop.right, leftValue);
        }
    }
}

import { Params } from './natural-mode-params';
export const COLOR__COROTATIONAL_CIRCLE = 0x00ff00;
export const COLOR__COUNTERROTATIONAL_CIRCLE = 0x0000ff;
export const RADII_COLOR = 0xff0000;
export const RADII_CONNECTING_LINE_COLOR = 0xff0000;
export const TORUS_TUBE = 0.3;
export const TORUS_RADIAL_SEGMENTS = 64;
export const TORUS_TUBULAR_SEGMENTS = 64;
export const DEFAULT_TORSION_LINE_SCALE_FACTOR = 0.3;
export const DEFAULT_RADIAL_SCALE_FACTOR = 2;
export const DEFAULT_FREQUENCY_INDEX = 0;
export const DEFAULT_PARAMETER_TO_VISUALIZE = Params.Displacement;
export const DEFAULT_PART_INDEX = 0;
export const NATURAL_FORMS_GROUP = 'Natural Forms Group';
export const TORSIONAL_LINE = 'Torsional Line';
export const PHASE_STEPS = 50;

import { Group } from 'konva/lib/Group';
import { Shape } from 'konva/lib/Shape';
import { Rect } from 'konva/lib/shapes/Rect';
import { TRANS_TOLERANCE } from '../../cae-model/cae-model-constants';
import {
    ANCHOR_COLOR,
    ANCHOR_SIZE_2D,
    DASH_STROKE_TRANSFORMER,
    CUSTOM_TRANSFORMER_ATTRIBUTE,
    STROKE_WIDTH,
    TRANSFORMER_BOUNDING,
    TRANSFORMER_GROUP_NAME,
} from '../elements-view/view-2d-constants';

export enum ANCHORS_NAMES {
    top_left = 'top_left',
    top_center = 'top_center',
    top_right = 'top_right',
    middle_right = 'middle_right',
    middle_left = 'middle_left',
    bottom_left = 'bottom_left',
    bottom_center = 'bottom_center',
    bottom_right = 'bottom_right',
}

export abstract class TransformerBase {
    protected _group: Group;
    protected _isVisible = false;
    protected _transformable = true;
    protected _onTransform: () => void;
    private _unitScaleFactor: number;

    constructor(private _shape: Shape, private _parent: Group) {}

    protected abstract updateElements(): void;

    public set unitScaleFactor(unitScaleFactor: number) {
        this._unitScaleFactor = unitScaleFactor;
    }

    set onTransform(onTransformFunc: () => void) {
        this._onTransform = onTransformFunc;
    }

    set appearance(visibility: { visible: boolean; transformable: boolean }) {
        this._isVisible = visibility.visible;
        this._transformable = visibility.transformable;
        if (this._isVisible) {
            this._visualize();
        } else {
            this._hide();
        }
    }

    private _createElements(): void {
        const names = Object.keys(ANCHORS_NAMES)
            .map(key => ANCHORS_NAMES[key as keyof typeof ANCHORS_NAMES])
            .filter(value => typeof value === 'string') as string[];
        names.forEach(name => this._createAnchor(name));
        this._createBounding();
    }

    private _createAnchor(name: string) {
        const anchor = new Rect({
            fill: ANCHOR_COLOR,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            name: name + ' _anchor',
            width: ANCHOR_SIZE_2D,
            height: ANCHOR_SIZE_2D,
            dragDistance: 0,
            // make it draggable,
            // so activating the anchror will not start drag&drop of any parent
            draggable: this._transformable,
            hitStrokeWidth: 10, // TOUCH_DEVICE ? 10 : 'auto',
            scaleX: 1 / this._unitScaleFactor, // fix selection box size
            scaleY: 1 / this._unitScaleFactor, // fix selection box size
        });

        const self = this;
        anchor.on('dragmove', () => self._callOnTransform(anchor));

        this._group.add(anchor);
    }

    private _createBounding(): void {
        this._group.add(
            new Shape({
                stroke: ANCHOR_COLOR,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                name: TRANSFORMER_BOUNDING,
                dash: DASH_STROKE_TRANSFORMER,
                listening: false,
            }),
        );
    }

    private _visualize() {
        if (this._shape.getLayer() === null) {
            return;
        }
        this._group?.destroy();
        this._group = new Group({
            name: TRANSFORMER_GROUP_NAME,
        });
        this._group.setAttr(CUSTOM_TRANSFORMER_ATTRIBUTE, true);
        // because _group should be added to the layer so that the transfomer visualization can be on the top
        // so we need to forward to event to the parent container (which has event handler logic)
        this._group.on('click', event => this._parent.fire('click', event));
        this._group.on('tap', event => this._parent.fire('tap', event));
        this._group.on('dblclick', event => this._parent.fire('dblclick', event));
        this._group.on('dbltap', event => this._parent.fire('dbltap', event));

        this._shape.getLayer()!.add(this._group);
        this._createElements();
        this.update();
    }

    update(): void {
        if (!this._isVisible) {
            return;
        }

        this._setGroupCoors();
        this.updateElements();
        this._group.getLayer()?.batchDraw();
    }

    private _setGroupCoors() {
        const rectPosAbs = this._shape.getAbsolutePosition();
        this._group.setAbsolutePosition({
            x: rectPosAbs.x + (this._shape.width() / 2) * this._shape.getAbsoluteScale().x,
            y: rectPosAbs.y + (this._shape.height() / 2) * this._shape.getAbsoluteScale().y,
        });
    }

    private _getSignY(anchorType: ANCHORS_NAMES) {
        if (anchorType === ANCHORS_NAMES.top_left || anchorType === ANCHORS_NAMES.top_center || anchorType === ANCHORS_NAMES.top_right) {
            return -1;
        } else if (
            anchorType === ANCHORS_NAMES.bottom_left ||
            anchorType === ANCHORS_NAMES.bottom_center ||
            anchorType === ANCHORS_NAMES.bottom_right
        ) {
            return 1;
        }
        return 0;
    }

    private _getSignX(anchorType: ANCHORS_NAMES) {
        if (anchorType === ANCHORS_NAMES.top_left || anchorType === ANCHORS_NAMES.middle_left || anchorType === ANCHORS_NAMES.bottom_left) {
            return -1;
        }
        if (
            anchorType === ANCHORS_NAMES.top_right ||
            anchorType === ANCHORS_NAMES.middle_right ||
            anchorType === ANCHORS_NAMES.bottom_right
        ) {
            return 1;
        }
        return 0;
    }

    private _callOnTransform(anchor: Rect) {
        const anchorType = this._getAnchorType(anchor);
        const width = this._shape.width();
        const height = this._shape.height();

        let widhtChange = 0;
        let heightChange = 0;

        const signX = this._getSignX(anchorType);
        const signY = this._getSignY(anchorType);

        const anchorX = Math.max(Math.abs(anchor.x()), TRANS_TOLERANCE) * signX;
        const anchorY = Math.max(Math.abs(anchor.y()), TRANS_TOLERANCE) * signY;

        anchor.x(anchorX);
        anchor.y(anchorY);

        widhtChange = (anchor.x() - (width / 2) * signX) * signX;
        heightChange = (anchor.y() - (height / 2) * signY) * signY * 2;

        this._shape.width(this._shape.width() + widhtChange);
        this._shape.height(this._shape.height() + heightChange);
        const xChange = signX < 0 ? -widhtChange : 0;
        this._shape.move({
            x: xChange,
            y: -heightChange / 2,
        });

        this.update();
        if (this._onTransform !== null) {
            this._onTransform();
        }
    }

    private _getAnchorType(anchor: Rect): ANCHORS_NAMES {
        const anchorTypeStr = anchor.name().split(' ')[0];
        return ANCHORS_NAMES[anchorTypeStr as keyof typeof ANCHORS_NAMES];
    }

    private _hide() {
        this._group?.destroy();
    }

    protected batchChangeChild(selector: string, attrs: any) {
        const node = this._group.findOne(selector);
        node.setAttrs(attrs);
    }
}

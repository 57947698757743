import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'bx-demo-info',
    templateUrl: './demo-info.component.html',
    styleUrl: './demo-info.component.scss',
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class DemoInfoComponent {}

export class ExportConstants {
    /**
     * Identifier for VG2 json format
     */
    public static readonly vg2Json = 'vg2json';
    /**
     * Identifier for Gltf text format
     */
    public static readonly gltfText = 'gltfText';
    /**
     * Identifier for VG2 xml format
     */
    public static readonly vg2Xml = 'vg2xml';
    /**
     * Identifier for REXS json format
     */
    public static readonly rexsJson = 'rexsJson';
    /**
     * Identifier for REXS xml format
     */
    public static readonly rexsXml = 'rexsXml';
    /**
     * Identifier for SimPack format
     */
    public static readonly simPack = 'simPack';
    /**
     * Identifier for SimDrive format
     */
    public static readonly simDrive = 'simDrive';
    /**
     * Identifier for PreonLab format
     */
    public static readonly preonLab = 'preonLab';
}

import { ModelElement } from './model-element';
import { ModeShapeSection } from './mode-shape-section';

export class ModeShape extends ModelElement {
    private _sections: ModeShapeSection[];
    private _modeShapeType: string;
    private _frequency: number;
    private _modeShapeMostExitedShaft: string;

    constructor() {
        super();
        this._sections = [];
    }

    public get modeShapeType(): string {
        return this._modeShapeType;
    }

    public set modeShapeType(modeShapeType: string) {
        this._modeShapeType = modeShapeType;
    }

    public get frequency(): number {
        return this._frequency;
    }

    public set frequency(frequency: number) {
        this._frequency = frequency;
    }

    public get modeShapeMostExitedShaft(): string {
        return this._modeShapeMostExitedShaft;
    }

    public get sections(): ModeShapeSection[] {
        return this._sections;
    }

    public set sections(sections: ModeShapeSection[]) {
        this._sections = sections;
    }

    public set modeShapeMostExitedShaft(modeShapeMostExitedShaft: string) {
        this._modeShapeMostExitedShaft = modeShapeMostExitedShaft;
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof ModeShapeSection) {
            this._addModeShapeSection(child);
        } else {
            throw new Error('Unexpected model element type!');
        }
    }

    private _addModeShapeSection(section: ModeShapeSection): void {
        this._sections.push(section);
    }
}

<div class="imprint">
    <h3 class="subtitle">{{ 'BEARINX.IMPRINT.SUBTITLE' | translate }}</h3>
    <div class="address">
        <p>{{ 'BEARINX.IMPRINT.ADDRESS.NAME' | translate }}</p>
        <p>{{ 'BEARINX.IMPRINT.ADDRESS.STREET' | translate }}</p>
        <p>{{ 'BEARINX.IMPRINT.ADDRESS.CITY' | translate }}</p>
        <p>{{ 'BEARINX.IMPRINT.ADDRESS.COUNTRY' | translate }}</p>
    </div>

    <p class="phone">{{ 'BEARINX.IMPRINT.PHONE' | translate }}</p>
    <p>Fax: +49 9132 82-49 50</p>

    <p class="email">
        <a
            class="icon icon-mail"
            data-widget="GATrakken"
            data-ar-event-category="Engagement"
            data-ar-event-action="Content/Mailto"
            data-ar-event-label="info@schaeffler.com mailto:info@schaeffler.com"
            href="mailto:info@schaeffler.com"
            target="_self"
            title="info@schaeffler.com "
            >info&#64;schaeffler.com
        </a>
    </p>

    <div class="seat">
        <p>
            <strong>{{ 'BEARINX.IMPRINT.SEAT.CITY.SEAT' | translate }}</strong> {{ 'BEARINX.IMPRINT.SEAT.CITY.SEAT_CITY' | translate }}
        </p>
        <p>
            <strong>{{ 'BEARINX.IMPRINT.SEAT.REGISTER_ADDRESS.NUMBER' | translate }}</strong>
            {{ 'BEARINX.IMPRINT.SEAT.REGISTER_ADDRESS.ADDRESS' | translate }}
        </p>
        <p>
            <strong>{{ 'BEARINX.IMPRINT.SEAT.TAX_ID.TAX' | translate }}</strong> {{ 'BEARINX.IMPRINT.SEAT.TAX_ID.ID' | translate }}
        </p>
    </div>

    <div class="register">
        <p>
            <strong>{{ 'BEARINX.IMPRINT.REGISTER.PARTHNER.PARTHNER' | translate }}</strong>
            {{ 'BEARINX.IMPRINT.REGISTER.PARTHNER.NAME' | translate }}
        </p>
        <p>
            <strong>{{ 'BEARINX.IMPRINT.SEAT.CITY.SEAT' | translate }}</strong> {{ 'BEARINX.IMPRINT.SEAT.CITY.SEAT_CITY' | translate }}
        </p>
        <p>
            <strong>{{ 'BEARINX.IMPRINT.REGISTER.COM_REGISTER.REG_ADDR' | translate }}</strong>
            {{ 'BEARINX.IMPRINT.REGISTER.COM_REGISTER.ADDRESS' | translate }}
        </p>
    </div>

    <p class="supervisors">
        <strong>{{ 'BEARINX.IMPRINT.SUPERVISORY.SUPERVISORY' | translate }}</strong> {{ 'BEARINX.IMPRINT.SUPERVISORY.NAME' | translate }}
    </p>

    <p class="directors">
        <strong>{{ 'BEARINX.IMPRINT.DIRECTORS.DIRECTORS' | translate }}</strong> {{ 'BEARINX.IMPRINT.DIRECTORS.NAMES' | translate }}
    </p>
    <p class="rstv">
        <strong>{{ 'BEARINX.IMPRINT.RSTV.RSTV' | translate }} </strong> {{ 'BEARINX.IMPRINT.RSTV.NAME' | translate }}
    </p>
    <p class="liability">
        <strong>{{ 'BEARINX.IMPRINT.LIABILITY.NOTICE' | translate }}</strong> {{ 'BEARINX.IMPRINT.LIABILITY.TEXT' | translate }}
    </p>
</div>
